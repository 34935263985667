import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  IPaymentDetails,
  IRegistrationDetails,
  ISubmissionMailing,
} from "./types";

export const useClinicSlice = createSlice({
  name: "universityExamDetails",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },

    doGetRegistrationDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    setRegistrationDetails: (
      state,
      action: PayloadAction<IRegistrationDetails>
    ) => {
      state.registrationDetails = action.payload;
    },

    doGetSubmissionMailingDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.buttonLoading = true;
    },
    setSubmissionMailingDetails: (
      state,
      action: PayloadAction<ISubmissionMailing>
    ) => {
      state.submissionMailing = action.payload;
    },
    doGetExamPaymentDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.buttonLoading = true;
    },
    setPaymentDetails: (
      state,
      action: PayloadAction<IPaymentDetails>
    ) => {
      state.paymentDetails = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
