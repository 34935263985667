import { StudentAuthState } from ".";

export const initialState: StudentAuthState = {
  studentRegister: {
    _id: "",
    seoTitle: "",
    name1: "",
    name2: "",
    email: "",
    confirmEmail: "",
    password: "",
    address: "",
    mobileNumber: "",
    dob: "",
    nameOfHighSchool: "",
    yearAndDateOfPass: "",
    department: "",
    graduated: false,
    profilePic: "",
  },

  loading: false,
  buttonLoading: false,
  studentLogin: {
    email: "",
    password: "",
    seoTitle: "",
  },
  forgotPassword: {
    password: "",
    confirmPassword: "",
    accessToken: "",
    seoTitle: "",
    email: "",
  },
  fileUpload: false,
  isEditable: false
};
