import { toast } from "sonner";
import {
  IBasicDetails,
  IEmail,
  IPassword,
} from "../../../Redux/UniversitySection/Setting/University/types";
import { t } from "i18next";
import { passwordRegex } from "../../../utils/constants/PasswordRegex";
import { emailRegex } from "../../../utils/constants";
import {
  IApplicationInformationData,
  IExamDetailsInformation,
} from "../../../Redux/UniversitySection/UniversityExam/Application&Examination/types";
import { LoginInterface } from "../../../Redux/Auth/types";
import {
  IStudent,
  IStudentForgotPassword,
} from "../../../Redux/Auth/Student/types";
import { ENUM_ADDITIONAL_FIELD_TYPE } from "../../../Redux/UniversitySection/UniversityExam/Submissions&Mailings/types";

export const passwordValidation = (form: IPassword) => {
  if (form?.oldPassword?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.OLD_PASSWORD_PROMPT")}`);
    return false;
  }
  if (form?.newPassword?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.ENTER_NEW_PASSWORD")}`);
    return false;
  }
  if (form?.newPassword?.length > 0 && !passwordRegex.test(form?.newPassword)) {
    toast.error(
      `${t("ERROR_MESSAGE.PASSWORD_ERROR.VALID_NEW_PASSWORD_PROMPT")}`
    );
    return false;
  }
  if (form?.newConfirmPassword?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.CONFIRM_PASSWORD_PROMPT")}`);
    return false;
  }
  if (
    form?.newConfirmPassword?.length > 0 &&
    !passwordRegex.test(form?.newConfirmPassword)
  ) {
    toast.error(
      `${t("ERROR_MESSAGE.PASSWORD_ERROR.VALID_CONFIRM_PASSWORD_PROMPT")}`
    );
    return false;
  }
  if (form?.newPassword !== form?.newConfirmPassword) {
    toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.PASSWORDS_SHOULD_MATCH")}`);
    return false;
  }
  return true;
};
export const basicDetailsValidations = (form: IBasicDetails) => {
  if (form?.title?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NAME")}`);
    return false;
  }
  if (form?.description?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_DESCRIPTION")}`);
    return false;
  }
  return true;
};
export const emailValidation = (form: IEmail) => {
  if (form?.currentEmail?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.OLD_EMAIL_PROMPT")}`);
    return false;
  }
  if (form?.currentEmail?.length > 0 && !emailRegex.test(form?.currentEmail)) {
    toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.ENTER_VALID_EMAIL")}`);
    return false;
  }
  if (form?.newEmail?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.NEW_EMAIL_PROMPT")}`);
    return false;
  }
  if (form?.newEmail?.length > 0 && !emailRegex.test(form?.newEmail)) {
    toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.VALID_NEW_EMAIL_PROMPT")}`);
    return false;
  }
  if (form?.newConfirmEmail?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.CONFIRM_EMAIL_PROMPT")}`);
    return false;
  }
  if (
    form?.newConfirmEmail?.length > 0 &&
    !emailRegex.test(form?.newConfirmEmail)
  ) {
    toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.VALID_CONFIRM_EMAIL_PROMPT")}`);
    return false;
  }
  if (form?.newEmail !== form?.newConfirmEmail) {
    toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.EMAIL_MUST_MATCH")}`);
    return false;
  }
  return true;
};
export const loginValidation = (form: LoginInterface, isStudent?: boolean) => {
  if (form?.email?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_EMAIL")}`);
    return false;
  }
  if (form?.email?.length > 0 && !emailRegex.test(form?.email)) {
    toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.ENTER_VALID_EMAIL")}`);
    return false;
  }
  if (form?.password?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.ENTER_PASSWORD")}`);
    return false;
  }
  // if (isStudent) {
  //   if (form?.password?.length > 0 && !passwordRegex.test(form?.password)) {
  //     toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.ENTER_VALID_PASSWORD")}`);
  //     return false;
  //   }
  // }

  return true;
};

export const feeSectionValidation = (
  form: IExamDetailsInformation,
  type: string
) => {
  if (type == "feeRemark") {
    if (
      form?.feeLastDate?.length === 0 ||
      form?.feeLastDate == "undefined" ||
      form?.feeLastDate == null ||
      form?.feeLastDate == undefined
    ) {
      toast.error(
        `${t(
          "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.FEE_ENTRY.FEE_DATE"
        )}`
      );
      return false;
    } else if (
      form?.feeAmount === 0 ||
      form?.feeAmount == undefined ||
      form?.feeAmount == null ||
      form?.feeAmount == undefined
    ) {
      toast.error(
        `${t(
          "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.FEE_ENTRY.FEE_AMOUNT"
        )}`
      );
      return false;
    } else if (
      form?.feeAmount < 50 ||
      form?.feeAmount == undefined ||
      form?.feeAmount == null ||
      form?.feeAmount == undefined
    ) {
      toast.error(
        `${t(
          "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.FEE_ENTRY.MIN_AMOUNT"
        )}`
      );
      return false;
    }
  } else if (type == "remark") {
    if (form?.remark?.length === 0 || form.remark == undefined) {
      toast.error(
        `${t(
          "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.FEE_ENTRY.REMARK"
        )}`
      );
      return false;
    }
  } else if (type == "examDate") {
    if (
      form?.examDate?.length === 0 ||
      form?.examDate == null ||
      form?.examDate == undefined
    ) {
      toast.error(
        `${t("ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_DATE")}`
      );
      return false;
    }
    if (
      form?.examPlace?.length === 0 ||
      form?.examPlace == null ||
      form?.examPlace == undefined
    ) {
      toast.error(
        `${t(
          "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_EXAM_PLACE"
        )}`
      );

      return false;
    }
  } else if (type == "hallTicket") {
    if (
      form?.hallTicketNumPrefix?.length === 0 ||
      form?.hallTicketNumPrefix == null ||
      form?.hallTicketNumPrefix == undefined
    ) {
      toast.error(
        `${t("ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_HALL_TICKET")}`
      );
      return false;
    }
  } else if (type == "pass_fail") {
    if (
      form?.examResultDate?.length === 0 ||
      form?.examResultDate == null ||
      form?.examResultDate == undefined
    ) {
      toast.error(
        `${t("ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_DATE")}`
      );
      return false;
    }
    if (
      form?.examResultPublishTime?.length === 0 ||
      form?.examResultPublishTime == null ||
      form?.examResultPublishTime == undefined
    ) {
      toast.error(
        `${t("ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_TIME")}`
      );
      return false;
    }
    // if (
    //   !form.examTestPoint ||
    //   form?.minTestResult == null ||
    //   form?.minTestResult == undefined
    // ) {
    //   toast.error(
    //     `${t(
    //       "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.SELECT_TEST_POINT"
    //     )}`
    //   );
    //   return false;
    // }
    if (form.examTestPoint) {
      if (
        form?.minTestResult === 0 ||
        form?.minTestResult == null ||
        form?.minTestResult == undefined
      ) {
        toast.error(
          `${t(
            "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_MAX_RESULT"
          )}`
        );
        return false;
      }
    }
  } else if (type == "pass_fail_content") {
    if (
      form?.examPassContent?.length === 0 ||
      form?.examPassContent == null ||
      form?.examPassContent == undefined
    ) {
      toast.error(
        `${t(
          "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_EXAM_PASS"
        )}`
      );
      return false;
    }
    if (
      form?.examFailContent?.length === 0 ||
      form?.examFailContent == null ||
      form?.examFailContent == undefined
    ) {
      toast.error(
        `${t(
          "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_EXAM_FAIL"
        )}`
      );
      return false;
    }
    if (form.isExamSpecialStudentContent) {
      if (
        form?.examSpecialStudentContent?.length === 0 ||
        form?.examSpecialStudentContent == null ||
        form?.examSpecialStudentContent == undefined
      ) {
        toast.error(
          `${t(
            "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_EXAM_SPECIAL_STUDENT"
          )}`
        );
        return false;
      }
    }
  } else if (type == "resultDate") {
    if (
      form?.examResultPublishDate?.length === 0 ||
      form?.examResultPublishDate == null ||
      form?.examResultPublishDate == undefined
    ) {
      toast.error(
        `${t("ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_DATE")}`
      );
      return false;
    }
  } else if (type == "enrolment") {
    if (
      form?.enrolmentContent?.length === 0 ||
      form?.enrolmentContent == null ||
      form?.enrolmentContent == undefined
    ) {
      toast.error(
        `${t(
          "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_ENROLMENT"
        )}`
      );
      return false;
    }
  }
  return true;
};
export const applicationRegistrationValidation = (
  form: IApplicationInformationData
) => {
  if (
    !form?.isAddress &&
    !form?.isDateOfBirth &&
    !form?.isDateOfGraduation &&
    !form?.isDepartment &&
    !form?.isName1 &&
    !form?.isName2 &&
    !form?.isNameOfHighSchool
  ) {
    toast.error(
      `${t(
        "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.AT_LEAST_ONE_CONTENT"
      )}`
    );
    return false;
  }

  if (form?.registrationAdditionalFields.length > 0) {
    for (
      let index = 0;
      index < form?.registrationAdditionalFields.length;
      index++
    ) {
      const element = form?.registrationAdditionalFields[index];
      if (element.title.length == 0) {
        toast.error(
          `${t(
            "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.SUBMISSIONS_MAILINGS.LABEL"
          )}`
        );
        return false;
      }
      // if (
      //   element.type == ENUM_ADDITIONAL_FIELD_TYPE.MULTI_SELECT ||
      //   element.type == ENUM_ADDITIONAL_FIELD_TYPE.SINGLE_SELECT
      // ) {
      //   for (
      //     let index = 0;
      //     index < form?.registrationAdditionalFields[index]?.options?.length;
      //     index++
      //   ) {
      //     const ele = form?.registrationAdditionalFields[index];
      //     if (ele?.options === "") {
      //       // toast.error(
      //       //   `${t(
      //       //     "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.ENTER_OPTIONS"
      //       //   )}`
      //       // );
      //       toast.error("please fill options");
      //       return false;
      //     }
      //   }
      // }
    }
  }
  return true;
};
export const studentRegisterValidation = (
  form: IStudent,
  forUpdate?: boolean
) => {
  if (form?.profilePic?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.ENTER_PROFILE_PIC")}`);
    return false;
  }
  if (form?.name1?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.ENTER_NAME_1")}`);
    return false;
  }
  if (form?.name2?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.ENTER_NAME_2")}`);
    return false;
  }
  if (form?.address?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.ENTER_ADDRESS")}`);
    return false;
  }
  if (form?.mobileNumber?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.ENTER_MOBILE")}`);
    return false;
  }
  if (form?.mobileNumber?.length < 9) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.ENTER_V_MOBILE")}`);
    return false;
  }
  if (form?.dob?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.SELECT_DOB")}`);
    return false;
  }
  if (!forUpdate) {
    if (form?.email?.length == 0) {
      toast.error(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_EMAIL")}`);
      return false;
    }
    if (form?.email?.length > 0 && !emailRegex.test(form?.email)) {
      toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.ENTER_VALID_EMAIL")}`);
      return false;
    }
    if (form?.confirmEmail?.length == 0) {
      toast.error(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_EMAIL")}`);
      return false;
    }
    if (
      form?.confirmEmail?.length > 0 &&
      !emailRegex.test(form?.confirmEmail)
    ) {
      toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.ENTER_VALID_EMAIL")}`);
      return false;
    }
    // if (form?.password?.length == 0) {
    //   toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.ENTER_PASSWORD")}`);
    //   return false;
    // }
    // if (form?.password?.length > 0 && !passwordRegex.test(form?.password)) {
    //   toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.ENTER_VALID_PASSWORD")}`);
    //   return false;
    // }
  }

  if (form?.nameOfHighSchool?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.SCHOOL_NAME")}`);
    return false;
  }
  if (form?.department?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.ENTER_DEPT")}`);
    return false;
  }
  if (form?.yearAndDateOfPass?.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.STUDENT_REGISTERED.PASS_YEAR")}`);
    return false;
  }

  return true;
};
export const passwordUpdateValidation = (
  form: IStudentForgotPassword,
  isEmail: boolean
) => {
  if (isEmail) {
    if (form?.email?.length == 0) {
      toast.error(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_EMAIL")}`);
      return false;
    }
    if (form?.email?.length > 0 && !emailRegex.test(form?.email)) {
      toast.error(`${t("ERROR_MESSAGE.EMAIL_ERROR.ENTER_VALID_EMAIL")}`);
      return false;
    }
  } else {
    if (form?.password?.length == 0) {
      toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.ENTER_PASSWORD")}`);
      return false;
    }
    if (form?.password?.length > 0 && !passwordRegex.test(form?.password)) {
      toast.error(`${t("ERROR_MESSAGE.PASSWORD_ERROR.ENTER_VALID_PASSWORD")}`);
      return false;
    }
    if (form?.confirmPassword?.length == 0) {
      toast.error(
        `${t("ERROR_MESSAGE.PASSWORD_ERROR.CONFIRM_PASSWORD_PROMPT")}`
      );
      return false;
    }
    if (
      form?.confirmPassword?.length > 0 &&
      !passwordRegex.test(form?.confirmPassword)
    ) {
      toast.error(
        `${t("ERROR_MESSAGE.PASSWORD_ERROR.VALID_CONFIRM_PASSWORD_PROMPT")}`
      );
      return false;
    }
    if (form?.password !== form?.confirmPassword) {
      toast.error(
        `${t("ERROR_MESSAGE.PASSWORD_ERROR.PASSWORDS_SHOULD_MATCH")}`
      );
      return false;
    }
  }
  return true;
};
