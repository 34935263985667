import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../types";

const selectDomain = (state: RootState) => {
  if (state && state.authState) {
    return state.authState;
  } else {
    return initialState;
  }
};

export const selectUserDataForm = createSelector(
  [selectDomain],
  (state) => state.userData
);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectIsLogin = createSelector(
  [selectDomain],
  (state) => state.isLogin
);
export const SelectToken = createSelector(
  [selectDomain],
  (state) => state.token
);
export const selectLoginForm = createSelector(
  [selectDomain],
  (state) => state.loginForm
);
export const selectIsToken = createSelector(
  [selectDomain],
  (state) => state.isToken
);
export const selectUniversityBasic = createSelector(
  [selectDomain],
  (state) => state.universityBasic
);
export const selectDetailsLoading = createSelector(
  [selectDomain],
  (state) => state.detailsLoading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
