export interface ITestName {
  _id: string;
  title: string;
  isPublished: boolean;
}
export interface IPostalAddress {
  postalAddress: string;
}
export interface ISchoolAddress {
  _id?: string;
  title: string;
  description: string;
  type: ENUM_ADDITIONAL_FIELD_TYPE;
  options: string[];
  fileSelectFileType?: ENUM_FILE_TYPE;
  isDeleted: boolean;
  maxNoOfFiles?: number;
  exam?: string;
}
export interface IUploadDocument {
  _id: string;
  title: string;
  fileSelectFileType: ENUM_FILE_TYPE;
  maxNoOfFiles: number;
  isDeleted?: boolean;
  description: string;
  exam?: string;
}
export interface ISchoolAddressData {
  _id: string;
  schoolAddress: ISchoolAddress[];
  schoolAddressForm: ISchoolAddress;
}
export interface IUploadDocumentData {
  _id: string;
  uploadDocumentFields: IUploadDocument[];
  uploadDocumentForm: IUploadDocument;
}
export interface IHallTicket {
  _id: string;
  remark: string;
}
export enum ENUM_ADDITIONAL_FIELD_TYPE {
  NONE_SELECTED = "NONE_SELECTED",
  TEXT = "TEXT",
  MULTI_SELECT = "MULTI_SELECT",
  SELECT_YES_NO = "SELECT_YES_NO",
  FILE_SELECT = "FILE_SELECT",
  TEXT_AREA = "TEXT_AREA",
  SINGLE_SELECT = "SINGLE_SELECT",
}

export enum ENUM_FILE_TYPE {
  PDF = "PDF",
  IMAGE = "IMAGE",
  DOC = "DOC",
}
export interface Submissions_MailingsState {
  testNameForm: ITestName | null;
  postalAddressForm: IPostalAddress;
  schoolAddressData: ISchoolAddressData;
  uploadDocument: IUploadDocumentData;
  isEditable: boolean;
  loading: boolean;
  hallTicket: IHallTicket;
}

export type InitialState = Submissions_MailingsState;
