import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, putType } from "../../../utils/request";
import {
  selectExamApplicationForm,
  selectExamApplicationInfoForm,
} from "./selector";
import { IExamApplicationForm, IExamApplicationInfoForm } from "./types";
import { t } from "i18next";
import { ENUM_ADDITIONAL_FIELD_TYPE } from "../../UniversitySection/UniversityExam/Submissions&Mailings/types";

export function* doGetExamNameRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `public/exam/get/name/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamNameData(response.data.data));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetExamValidationsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setVLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/validation/data/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setVLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamValidationsData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setVLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetApplicationFormFieldRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/registration/fields/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamApplicationForm(response.data.data[0]));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getApplicationFormInfoRequest(action: { payload: string }) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/registration/data/${action.payload}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamApplicationInfoForm(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* updateApplicationInfoRequest(action: {
  payload: { id: string; callback: (id: string) => void };
}) {
  yield delay(500);
  const form: IExamApplicationInfoForm = yield select(
    selectExamApplicationInfoForm
  );
  const formField: IExamApplicationForm = yield select(
    selectExamApplicationForm
  );
  let isError = false;
  if (formField.isProfilePic) {
    if (form.profilePic.trim().length == 0) {
      toast.error(`${t("STUDENT_APPLICATION_FROM.ERRORS.PROFILE_PIC")}`);

      isError = true;
    }
  }
  if (formField.isAddress) {
    if (form.address.trim().length == 0) {
      toast.error(`${t("STUDENT_APPLICATION_FROM.ERRORS.ADDRESS")}`);

      isError = true;
    }
  }

  if (formField.isDateOfBirth) {
    if (form.dateOfBirth.trim().length == 0) {
      toast.error(`${t("STUDENT_APPLICATION_FROM.ERRORS.DBO")}`);

      isError = true;
    }
  }
  if (formField.isDateOfGraduation) {
    if (form.dateOfGraduation.trim().length == 0) {
      toast.error(`${t("STUDENT_APPLICATION_FROM.ERRORS.DATE_OF_GRADUATION")}`);

      isError = true;
    }
  }
  if (formField.isDepartment) {
    if (form.department.trim().length == 0) {
      toast.error(`${t("STUDENT_APPLICATION_FROM.ERRORS.DEPARTMENT")}`);

      isError = true;
    }
  }
  if (formField.isName1) {
    if (form.name1.trim().length == 0) {
      toast.error(`${t("STUDENT_APPLICATION_FROM.ERRORS.NAME1")}`);

      isError = true;
    }
  }
  if (formField.isName2) {
    if (form.name2.trim().length == 0) {
      toast.error(`${t("STUDENT_APPLICATION_FROM.ERRORS.NAME2")}`);

      isError = true;
    }
  }
  if (formField.isNameOfHighSchool) {
    if (form.nameOfHighSchool.trim().length == 0) {
      toast.error(
        `${t("STUDENT_APPLICATION_FROM.ERRORS.NAME_OF_HIGH_SCHOOL")}`
      );

      isError = true;
    }
  }
  for (
    let index = 0;
    index < formField.registrationAdditionalFields.length;
    index++
  ) {
    const element = formField.registrationAdditionalFields[index];

    if (element?.type !== ENUM_ADDITIONAL_FIELD_TYPE.NONE_SELECTED) {
      if (
        form.additionalFieldsData.findIndex((x) => x._id == element._id) == -1
      ) {
        toast.error(
          `${t("STUDENT_APPLICATION_FROM.ERRORS.ADDITIONAL_FILED", {
            name: element.title,
          })}`
        );

        isError = true;
      } else if (
        !form.additionalFieldsData[
          form.additionalFieldsData.findIndex((x) => x._id == element._id)
        ].values
      ) {
        toast.error(
          `${t("STUDENT_APPLICATION_FROM.ERRORS.ADDITIONAL_FILED", {
            name: element.title,
          })}`
        );

        isError = true;
      } else if (
        form.additionalFieldsData[
          form.additionalFieldsData.findIndex((x) => x._id == element._id)
        ].values.length == 0
      ) {
        toast.error(
          `${t("STUDENT_APPLICATION_FROM.ERRORS.ADDITIONAL_FILED", {
            name: element.title,
          })}`
        );
        isError = true;
      }
    }
  }

  if (isError) {
    yield put(actions.setButtonLoading(false));
    return;
  }
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      putType,
      `student/exam/update/registration/data`,
      form
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    //@ts-ignore
    yield call(action.payload.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* StudentExamApplicationRepoSaga() {
  yield takeLatest(actions.doGetExamName, doGetExamNameRequest);
  yield takeLatest(actions.doGetExamValidations, doGetExamValidationsRequest);
  yield takeLatest(
    actions.doGetApplicationFormField,
    doGetApplicationFormFieldRequest
  );
  yield takeLatest(
    actions.doUpdateApplicationInfoForm,
    updateApplicationInfoRequest
  );
  yield takeLatest(
    actions.doGetApplicationInfoForm,
    getApplicationFormInfoRequest
  );
}
