import axios from "axios";
let app_language = "";
let show_toggle = import.meta.env.VITE_APP_SHOW_TOGGLE;
let app_logger = "";
let url = import.meta.env.VITE_APP_API;
let stag_url = import.meta.env.VITE_APP_ASSETS_URL;
app_language = import.meta.env.VITE_APP_LANGUAGE || "";
app_logger = import.meta.env.VITE_APP_LOG || "true";
export const path = () => {
  return stag_url;
};

export default axios.create({
  baseURL: url,
  headers: {},
});
export const apiPath = () => {
  return url;
};

export const APP_LANGUAGE = app_language;
export const APP_LOGGER = Boolean(app_logger);
export const SHOW_TOGGLE = show_toggle;
