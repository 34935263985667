import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { IExamUploadForm, IExamUploadFormFields } from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "studentExamUploadDoc",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    doGetUploadDocFormField: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    setExamUploadDocFormFields: (
      state,
      action: PayloadAction<IExamUploadFormFields>
    ) => {
      state.postalAddress = action.payload.postalAddress;
      state.uploadDocumentFields = action.payload.uploadDocumentFields;
      state.schoolAddressFields = action.payload.schoolAddressFields;
    },
    clearStudentApplicationForm: (state) => {
      state.uploadDocForm._id = "";
      state.uploadDocForm.createdAt = "";
      state.uploadDocForm.schoolAddressFieldsData = [];
      state.uploadDocForm.schoolAddressFieldsData = [];
    },
    updateApplicationInfoFormAddressFieldsValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const index = state.uploadDocForm.schoolAddressFieldsData.findIndex(
        (x) => x._id == action.payload.key
      );
      if (index > -1) {
        set(
          state,
          `uploadDocForm.schoolAddressFieldsData[${index}].values`,
          action.payload.value
        );
      } else {
        state.uploadDocForm.schoolAddressFieldsData.push({
          _id: action.payload.key,
          values: action.payload.value,
        });
      }
    },
    updateApplicationInfoFormUploadDocumentFieldsValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const index = state.uploadDocForm.uploadDocumentFieldsData.findIndex(
        (x) => x._id == action.payload.key
      );
      if (index > -1) {
        set(
          state,
          `uploadDocForm.uploadDocumentFieldsData[${index}].values`,
          action.payload.value
        );
      } else {
        state.uploadDocForm.uploadDocumentFieldsData.push({
          _id: action.payload.key,
          values: action.payload.value,
        });
      }
    },
    getSignedUrl: (
      state,
      action: PayloadAction<{
        fileName: string;
        callback: (url: string, prefix: string) => void;
      }>
    ) => {},
    doUpdateMailingSubmissionForm: (
      state,
      action: PayloadAction<{ id: string; callback: (id: string) => void }>
    ) => {},
    doGetMailingSubmissionFrom: (state, action: PayloadAction<string>) => {},

    setMailingSubmissionForm: (
      state,
      action: PayloadAction<IExamUploadForm>
    ) => {
      state.uploadDocForm._id = action.payload._id;
      state.uploadDocForm.schoolAddressFieldsData =
        action.payload.schoolAddressFieldsData.map((x) => {
          return { _id: x.fieldId, values: x.values };
        });
      state.uploadDocForm.uploadDocumentFieldsData =
        action.payload.uploadDocumentFieldsData.map((x) => {
          return { _id: x.fieldId, values: x.values };
        });
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
