import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, patch } from "../../../utils/request";
import {
  selectExamStudentList,
  selectOrderBy,
  selectOrderDir,
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectStudentOrderBy,
  selectStudentOrderDir,
  selectStudentPageNo,
  selectStudentPageSize,
} from "./selector";
import { IExamStudentList } from "./types";

export function* doGetExamListRequest() {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const orderBy: string = yield select(selectOrderBy);
    const orderDir: string = yield select(selectOrderDir);
    const search: string = yield select(selectSearch);
    const response: AxiosResponse = yield call(
      get,
      `university/exam/list?pageNo=${pageNo}&pageSize=${pageSize}${
        search.length > 0 ? "&search=" + search : ""
      }${orderBy.length > 0 ? "&orderBy=" + orderBy : ""}${
        orderDir.length > 0 ? "&orderDirection=" + orderDir : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.totalPages : 0
      )
    );
    yield put(actions.setExamList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doPublishExamRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/publish/${action.payload.id}`,
      {}
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetExamStudentListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  try {
    const pageNo: number = yield select(selectStudentPageNo);
    const pageSize: number = yield select(selectStudentPageSize);
    const orderBy: string = yield select(selectStudentOrderBy);
    const orderDir: string = yield select(selectStudentOrderDir);
    const search: string = yield select(selectSearch);
    const response: AxiosResponse = yield call(
      get,
      `/university/exam/get/registered/student/list/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }?pageNo=${pageNo}&pageSize=${pageSize}${
        search.length > 0 ? "&search=" + search : ""
      }${orderBy.length > 0 ? "&orderBy=" + orderBy : ""}${
        orderDir.length > 0 ? "&orderDirection=" + orderDir : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(
      actions.setStudentTotalPages(
        response.data.data.meta ? response.data.data.meta.totalPages : 0
      )
    );
    yield put(actions.setStudentExamList(response.data.data.results));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateMarksRequest(action: {
  payload: { index: number; id: string; callback: any };
}) {
  yield delay(500);
  const list: IExamStudentList[] = yield select(selectExamStudentList);
  const data = {
    marks: Number(list[action.payload.index].marks),
  };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `/university/exam/update/student/marks/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdatePassFailRequest(action: {
  payload: { index: number; id: string; callback: any };
}) {
  yield delay(500);

  const list: IExamStudentList[] = yield select(selectExamStudentList);
  const data = {
    result: list[action.payload.index].result,
  };

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `/university/exam/update/student/result/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

// exam details

export function* UniversityExamListRepoSaga() {
  yield takeLatest(actions.doGetExamList, doGetExamListRequest);
  yield takeLatest(actions.doPublishExam, doPublishExamRequest);
  yield takeLatest(actions.doGetExamStudentList, doGetExamStudentListRequest);
  yield takeLatest(actions.doUpdateMarks, doUpdateMarksRequest);
  yield takeLatest(actions.doUpdatePassFail, doUpdatePassFailRequest);
}
