import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { IExamList, IExamStudentList } from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "universityExamList",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    toggleConfirmModal: (state, action: PayloadAction<boolean>) => {
      state.confirmModal = action.payload;
    },
    toggleExamPreview: (state, action: PayloadAction<boolean>) => {
      state.examPreview = action.payload;
    },
    setSelectedId: (state, action: PayloadAction<string | null>) => {
      state.selectedId = action.payload;
    },
    setSelectedIndex: (state, action: PayloadAction<number | null>) => {
      state.selectedIndex = action.payload;
    },
    setSelectedType: (state, action: PayloadAction<number | null>) => {
      state.selectedType = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setOrderDir: (state, action: PayloadAction<string>) => {
      state.orderDir = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    doGetExamList: (state) => {
      state.loading = true;
    },
    setExamList: (state, action: PayloadAction<Array<IExamList>>) => {
      state.examList = action.payload;
    },
    doPublishExam: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doGetExamStudentList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    setStudentPageNo: (state, action: PayloadAction<number>) => {
      state.studentPageNo = action.payload;
    },
    setStudentPageSize: (state, action: PayloadAction<number>) => {
      state.studentPageSize = action.payload;
    },
    setStudentTotalPages: (state, action: PayloadAction<number>) => {
      state.studentTotalPages = action.payload;
    },

    setStudentOrderDir: (state, action: PayloadAction<string>) => {
      state.studentOrderDir = action.payload;
    },
    setStudentOrderBy: (state, action: PayloadAction<string>) => {
      state.studentOrderBy = action.payload;
    },
    setStudentExamList: (
      state,
      action: PayloadAction<Array<IExamStudentList>>
    ) => {
      state.studentList = action.payload;
    },
    doUpdateMarks: (
      state,
      action: PayloadAction<{ index: number; id: string; callback: () => void }>
    ) => {},
    doUpdatePassFail: (
      state,
      action: PayloadAction<{ index: number; id: string; callback: () => void }>
    ) => {},

    updateFormValue: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      set(
        state,
        `studentList[${action.payload.index}].${action.payload.key}`,
        action.payload.value
      );
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
