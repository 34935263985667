import axios from "axios";
import Api from "./Api";

const header = () => {
  return {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "x-custom-lang": localStorage.getItem("i18nextLng") || "en",
  };
};

const post = async (url: string, data: any) => {
  return Api.post(url, data, {
    headers: header(),
  });
};
const putType = async (url: string, data: any) => {
  return Api.put(url, data, {
    headers: header(),
  });
};
const patch = async (url: string, data: any) => {
  return Api.patch(url, data, {
    headers: header(),
  });
};
const get = async (url: string) => {
  return Api.get(url, {
    headers: header(),
  });
};
const deleteType = async (url: string) => {
  return Api.delete(url, {
    headers: header(),
  });
};
const uploadImg = async (signedUrl: any, data: any, contentType: any) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      "Content-Type": contentType,
    },
  });
};
export { post, putType, get, deleteType, patch, uploadImg };
