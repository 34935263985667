import { StudentExamApplicationFormState } from ".";
import { STUDENT_EXAM_RESULT_STATUS_ENUM } from "../../../UniversitySection/ExamList/types";

export const initialState: StudentExamApplicationFormState = {
  loading: false,
  buttonLoading: false,
  applicationForm: {
    _id: "",
    exam: "",
    isName1: false,
    isName2: false,
    isAddress: false,
    isNameOfHighSchool: false,
    isDepartment: false,
    isDateOfGraduation: false,
    isDateOfBirth: false,
    createdAt: "",
    registrationAdditionalFields: [],
    isProfilePic: false,
  },
  applicationInfoForm: {
    _id: "",
    examId: "",
    address: "",
    dateOfBirth: "",
    dateOfGraduation: "",
    department: "",
    name1: "",
    name2: "",
    nameOfHighSchool: "",
    additionalFieldsData: [],
    profilePic: "",
  },
  examName: {
    _id: "",
    title: "",
  },
  validationData: null,
  vLoading: false,
};
