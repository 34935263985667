import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.applicationExamination) {
    return state.applicationExamination;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectIsEditable = createSelector(
  [selectDomain],
  (state) => state.isEditable
);
export const selectApplicationInformationData = createSelector(
  [selectDomain],
  (state) => state.applicationInformation
);
export const selectExamDetailsInformation= createSelector(
  [selectDomain],
  (state) => state.examDetailsInformation
);
export const selectSelectedIndex = createSelector(
  [selectDomain],
  (state) => state.isSelectedIndex
);