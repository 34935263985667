import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, patch, putType } from "../../../../utils/request";
import {
  selectApplicationInformationData,
  selectExamDetailsInformation,
} from "./selector";
import { IApplicationInformationData, IExamDetailsInformation } from "./types";
import {
  applicationRegistrationValidation,
  feeSectionValidation,
} from "../../../../Components/Common/Validations";

export function* doGetApplicationInformationRequest(action: {
  payload: { id: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setIsEditable(true));
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/registration/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setIsEditable(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setApplicationInformation(response.data.data[0]));
  } catch (error: any) {
    yield put(actions.setIsEditable(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateApplicationInformationRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IApplicationInformationData = yield select(
    selectApplicationInformationData
  );
  const isValid = applicationRegistrationValidation(form);
  if (!isValid) {
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      putType,
      `university/exam/update/registration/${action.payload.examId}`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

///********** */
export function* doGetExamDetailsInformationRequest(action: {
  payload: { id: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setIsEditable(true));
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/exam/details/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setIsEditable(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamDetailsInformation(response.data.data));
  } catch (error: any) {
    yield put(actions.setIsEditable(false));
    CatchBlockFunction(error);
  }
}
/************** */
export function* doUpdateFeeEntryRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IExamDetailsInformation = yield select(
    selectExamDetailsInformation
  );

  const isValid = feeSectionValidation(form, "feeRemark");
  if (!isValid) {
    return;
  }
  yield put(actions.setLoading(true));
  const data = {
    feeLastDate: form?.feeLastDate,
    feeAmount: Number(form?.feeAmount),
    feeRemark: form?.feeRemark,
  };

  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/fee/details/${action.payload.examId}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/************** */
export function* doUpdateRemarkRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IExamDetailsInformation = yield select(
    selectExamDetailsInformation
  );
  const isValid = feeSectionValidation(form, "remark");
  if (!isValid) {
    return;
  }
  yield put(actions.setLoading(true));
  const data = {
    remark: form?.remark,
  };

  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/remark/${action.payload.examId}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/************** */
export function* doUpdateExamDateRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IExamDetailsInformation = yield select(
    selectExamDetailsInformation
  );
  const data = {
    examDate: form?.examDate,
    examPlace: form?.examPlace,
    examDateRemark: form?.examDateRemark,
  };
  const isValid = feeSectionValidation(form, "examDate");
  if (!isValid) {
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/examination/date/${action.payload.examId}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateExamHallTicketNumberRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IExamDetailsInformation = yield select(
    selectExamDetailsInformation
  );
  const data = {
    hallTicketNumPrefix: form?.hallTicketNumPrefix,
  };
  const isValid = feeSectionValidation(form, "hallTicket");
  if (!isValid) {
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/examination/hall/ticket/prefix/${action.payload.examId}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/************** */
export function* doUpdateExamPassFailDateRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IExamDetailsInformation = yield select(
    selectExamDetailsInformation
  );

  const isValid = feeSectionValidation(form, "pass_fail");
  if (!isValid) {
    return;
  }
  const data = {
    examResultDate: form?.examResultDate,
    examResultRemark: form?.examResultRemark,
    examResultPublishTime: form?.examResultPublishTime,
    examTestPoint: form?.examTestPoint,
    offset: form?.offset,
    minTestResult: Number(form?.minTestResult),
  };
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/result/date/${action.payload.examId}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/************** */
export function* doUpdateExamPassFailContentRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IExamDetailsInformation = yield select(
    selectExamDetailsInformation
  );
  const data = {
    examPassContent: form?.examPassContent,
    examFailContent: form?.examFailContent,
    isExamSpecialStudentContent: form?.isExamSpecialStudentContent,
    examSpecialStudentFailContent: form?.examSpecialStudentFailContent,
    examSpecialStudentPassContent: form?.examSpecialStudentPassContent,
  };

  const isValid = feeSectionValidation(form, "pass_fail_content");
  if (!isValid) {
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/result/content/${action.payload.examId}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/************** */
export function* doUpdateResultDateRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IExamDetailsInformation = yield select(
    selectExamDetailsInformation
  );
  const data = {
    examResultPublishDate: form?.examResultPublishDate,
    examResultPublishTime: form?.examResultPublishTime,
    examTestPoint: form?.examTestPoint,
    offset: form?.offset,
  };
  const isValid = feeSectionValidation(form, "resultDate");
  if (!isValid) {
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/publish/date/${action.payload.examId}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/************** */
export function* doUpdateEnrolmentRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IExamDetailsInformation = yield select(
    selectExamDetailsInformation
  );
  const data = {
    enrolmentContent: form?.enrolmentContent,
    enrolmentRemark: form?.enrolmentRemark,
  };
  const isValid = feeSectionValidation(form, "enrolment");
  if (!isValid) {
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/enrolment/${action.payload.examId}`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* Application_ExaminationRepoSaga() {
  /* ****** */
  yield takeLatest(
    actions.doGetApplicationInformation,
    doGetApplicationInformationRequest
  );

  yield takeLatest(
    actions.doUpdateApplicationInformation,
    doUpdateApplicationInformationRequest
  );
  /* ***** */
  /* ***** */
  yield takeLatest(
    actions.doGetExamDetailsInformation,
    doGetExamDetailsInformationRequest
  );
  /* ***** */
  /* ***** */
  yield takeLatest(actions.doUpdateFeeEntry, doUpdateFeeEntryRequest);
  /* ***** */
  /* ***** */
  yield takeLatest(actions.doUpdateRemark, doUpdateRemarkRequest);
  /* ***** */
  /* ***** */
  yield takeLatest(actions.doUpdateExamDate, doUpdateExamDateRequest);
  yield takeLatest(
    actions.doUpdateExamHallTicketNumber,
    doUpdateExamHallTicketNumberRequest
  );
  /* ***** */
  /* ***** */
  yield takeLatest(
    actions.doUpdateExamPassFailDate,
    doUpdateExamPassFailDateRequest
  );
  yield takeLatest(
    actions.doUpdateExamPassFailContent,
    doUpdateExamPassFailContentRequest
  );
  /* ***** */
  /* ***** */

  yield takeLatest(actions.doUpdateResultDate, doUpdateResultDateRequest);
  /* ***** */
  /* ***** */

  yield takeLatest(actions.doUpdateEnrolment, doUpdateEnrolmentRequest);
  /* ***** */
}
