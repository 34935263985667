import { StudentExamUploadDocState } from ".";

export const initialState: StudentExamUploadDocState = {
  loading: false,
  buttonLoading: false,
  uploadDocForm: {
    _id: "",
    uploadDocumentFieldsData: [],
    schoolAddressFieldsData: [],
    createdAt: "",
  },
  uploadDocumentFields: [],
  schoolAddressFields: [],
  postalAddress: ""
};
