import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as actionsAuth } from "../../../Auth/slice";
import { AxiosResponse } from "axios";
import {
  selectBasicDetailsForm,
  selectEmailForm,
  selectPasswordForm,
  selectPrivacyPolicyForm,
} from "./selector";
import { IBasicDetails, IEmail, IPassword, IPrivacyPolicy } from "./types";
import CatchBlockFunction from "../../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, patch, putType } from "../../../../utils/request";
import {
  basicDetailsValidations,
  emailValidation,
  passwordValidation,
} from "../../../../Components/Common/Validations";
import { t } from "i18next";

export function* doPasswordUpdateRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: IPassword = yield select(selectPasswordForm);
  const isValid = passwordValidation(form);
  if (!isValid) {
    return;
  }

  let data = {
    oldPassword: btoa(form.oldPassword),
    newPassword: btoa(form.newPassword),
    newConfirmPassword: btoa(form.newConfirmPassword),
  };

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/update/password`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    toast.success(response.data.message);
    yield put(actionsAuth.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback(response.data.data.accessToken));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetProfileDetailsRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(get, `university/get/details`);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setBasicDetailsData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doBasicDetailsUpdateRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: IBasicDetails = yield select(selectBasicDetailsForm);
  const isValid = basicDetailsValidations(form);
  if (!isValid) {
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      putType,
      `university/update/details`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doEmailUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: IEmail = yield select(selectEmailForm);
  const isValid = emailValidation(form);
  if (!isValid) {
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/update/email`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetPrivacyPolicyRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(get, `university/get/policy`);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setPrivacyPolicyData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doPrivacyPolicyUpdateRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: IPrivacyPolicy = yield select(selectPrivacyPolicyForm);
  if (form.privacyPolicy.length == 0) {
    toast.error(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_PRIVACY_POLICY")}`);
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/update/policy`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* UniversitySettingsRepoSaga() {
  yield takeLatest(actions.doUpdatePassword, doPasswordUpdateRequest);

  yield takeLatest(actions.doGetBasicDetails, doGetProfileDetailsRequest);
  yield takeLatest(actions.doBasicDetailsUpdate, doBasicDetailsUpdateRequest);

  yield takeLatest(actions.doEmailUpdate, doEmailUpdateRequest);

  yield takeLatest(actions.doPrivacyPolicyUpdate, doPrivacyPolicyUpdateRequest);
  yield takeLatest(actions.doGetPrivacyPolicy, doGetPrivacyPolicyRequest);
}
