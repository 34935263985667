import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, patch, putType } from "../../../../utils/request";
import {
  IHallTicket,
  IPostalAddress,
  ISchoolAddressData,
  ITestName,
  IUploadDocumentData,
} from "./types";
import {
  selectHallTicketForm,
  selectPostalAddressForm,
  selectSchoolAddressData,
  selectTestNameForm,
  selectUploadDocument,
} from "./selector";
import { t } from "i18next";
//***** */
export function* doCreateTestNameRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ITestName = yield select(selectTestNameForm);
  if (form?.title.length == 0) {
    toast.error(
      `${t(
        "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.SUBMISSIONS_MAILINGS.TEST_NAME"
      )}`
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      putType,
      `university/exam/create`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetTestNameRequest(action: { payload: { id: string } }) {
  yield delay(500);
  try {
    yield put(actions.setIsEditable(true));
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setIsEditable(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTestNameData(response.data.data));
  } catch (error: any) {
    yield put(actions.setIsEditable(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateTestNameRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ITestName = yield select(selectTestNameForm);

  if (form?.title.length == 0) {
    toast.error(
      `${t(
        "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.SUBMISSIONS_MAILINGS.TEST_NAME"
      )}`
    );
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/${form?._id}`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
//****** */
//*** */
export function* doGetPostalAddressRequest(action: {
  payload: { id: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setIsEditable(true));
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/postal/address/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setIsEditable(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setPostalAddressData(response.data.data));
  } catch (error: any) {
    yield put(actions.setIsEditable(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdatePostalAddressRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IPostalAddress = yield select(selectPostalAddressForm);
  if (form?.postalAddress.length == 0) {
    toast.error(
      `${t(
        "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.SUBMISSIONS_MAILINGS.POSTAL_ADDRESS"
      )}`
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/postal/address/${action.payload.examId}`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
//****** */
//*** */
export function* doGetSchoolAddressRequest(action: {
  payload: { id: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setIsEditable(true));
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/school/address/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setIsEditable(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setSchoolAddressData(response.data.data));
  } catch (error: any) {
    yield put(actions.setIsEditable(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateSchoolAddressRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: ISchoolAddressData = yield select(selectSchoolAddressData);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      putType,
      `university/exam/update/school/address/${action.payload.examId}`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
} //****** */
//*** */
export function* doGetUploadDocumentRequest(action: {
  payload: { id: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setIsEditable(true));
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/upload/document/fields/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setIsEditable(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setSUploadDocumentData(response.data.data));
  } catch (error: any) {
    yield put(actions.setIsEditable(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateUploadDocumentRequest(action: {
  payload: { examId: string; callback: any };
}) {
  yield delay(500);
  const form: IUploadDocumentData = yield select(selectUploadDocument);
  if (form?.uploadDocumentFields.length > 0) {
    for (let index = 0; index < form?.uploadDocumentFields.length; index++) {
      const element = form?.uploadDocumentFields[index];
      if (element.title.length == 0) {
        toast.error(
          `${t(
            "ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.SUBMISSIONS_MAILINGS.LABEL"
          )}`
        );
        return;
      }
    }
  }
  yield put(actions.setLoading(true));

  try {
    const response: AxiosResponse = yield call(
      putType,
      `university/exam/update/upload/document/${action.payload.examId}`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
//****** */
export function* doUpdateHallTicketRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: IHallTicket = yield select(selectHallTicketForm);
  if (form?.remark.length == 0) {
    toast.error(
      `${t("ERROR_MESSAGE.REGISTRATION_OF_EXAMINATION_FORMS.FEE_ENTRY.REMARK")}`
    );
    return;
  }
  const data = {
    remark: form.remark,
  };
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `university/exam/update/hall/ticket/remark`,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetHallTicketRequest() {
  yield delay(500);
  try {
    yield put(actions.setIsEditable(true));
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/hall/ticket/remark`
    );
    yield put(actions.setIsEditable(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setHallTicketData(response.data.data));
  } catch (error: any) {
    yield put(actions.setIsEditable(false));
    CatchBlockFunction(error);
  }
}
export function* Submissions_MailingsRepoSaga() {
  //test name start
  yield takeLatest(actions.doCreateTestName, doCreateTestNameRequest);
  yield takeLatest(actions.doGetTestName, doGetTestNameRequest);
  yield takeLatest(actions.doUpdateTestName, doUpdateTestNameRequest);
  //test name end
  //postal address start
  yield takeLatest(actions.doGetPostalAddress, doGetPostalAddressRequest);
  yield takeLatest(actions.doUpdatePostalAddress, doUpdatePostalAddressRequest);
  //postal address end
  /* ****** */
  yield takeLatest(actions.doGetSchoolAddress, doGetSchoolAddressRequest);
  yield takeLatest(actions.doUpdateSchoolAddress, doUpdateSchoolAddressRequest);
  /* ***** */
  /* ****** */
  yield takeLatest(actions.doGetUploadDocument, doGetUploadDocumentRequest);
  yield takeLatest(
    actions.doUpdateUploadDocument,
    doUpdateUploadDocumentRequest
  );
  /* ***** */
  /* ****** */
  yield takeLatest(actions.doUpdateHallTicket, doUpdateHallTicketRequest);
  yield takeLatest(actions.doGetHallTicket, doGetHallTicketRequest);
  /* ***** */
}
