import { StudentExamListState } from ".";

export const initialState: StudentExamListState = {
  loading: false,
  pageNo: 1,
  pageSize: 8,
  totalRow: 0,
  examList: [],
  appliedExamList: [],
  appliedExamPageNo: 1,
  appliedExamPageSize: 8,
  appliedExamTotalRow: 0,
  orderBy: "",
  orderDir: "",
  appliedExamOrderBy: "",
  appliedExamOrderDir: "",
};
