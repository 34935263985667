import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { IExamList } from "../../UniversitySection/ExamList/types";

export const useClinicSlice = createSlice({
  name: "studentExamList",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.orderDir = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    doGetStudentExamList: (state) => {
      state.loading = true;
    },

    setStudentExamList: (state, action: PayloadAction<Array<IExamList>>) => {
      state.examList = action.payload;
    },

    doGetStudentAppliedExamList: (state) => {
      state.loading = true;
    },
    setStudentAppliedExamList: (state, action: PayloadAction<Array<IExamList>>) => {
      state.appliedExamList = action.payload;
    },
    setAppliedPageNo: (state, action: PayloadAction<number>) => {
      state.appliedExamPageNo = action.payload;
    },
    setAppliedPageSize: (state, action: PayloadAction<number>) => {
      state.appliedExamPageSize = action.payload;
    },
    setAppliedTotalRow: (state, action: PayloadAction<number>) => {
      state.appliedExamTotalRow = action.payload;
    },
    setAppliedSortDir: (state, action: PayloadAction<string>) => {
      state.appliedExamOrderDir = action.payload;
    },
    setAppliedSortColumn: (state, action: PayloadAction<string>) => {
      state.appliedExamOrderBy = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
