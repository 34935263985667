import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  IExamApplicationForm,
  IExamApplicationInfoForm,
  IExamName,
  IValidations,
} from "./types";
import { set } from "lodash";
import { IStudent } from "../../Auth/Student/types";

export const useClinicSlice = createSlice({
  name: "studentExamForm",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setVLoading: (state, action: PayloadAction<boolean>) => {
      state.vLoading = action.payload;
    },
    doGetExamValidations: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.vLoading = true;
    },
    setExamValidationsData: (
      state,
      action: PayloadAction<IValidations | null>
    ) => {
      state.validationData = action.payload;
    },
    doGetApplicationFormField: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    doGetExamName: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    setExamNameData: (state, action: PayloadAction<IExamName>) => {
      state.examName = action.payload;
    },

    setExamApplicationForm: (
      state,
      action: PayloadAction<IExamApplicationForm>
    ) => {
      state.applicationForm = action.payload;
    },
    setExamApplicationInfoForm: (
      state,
      action: PayloadAction<IExamApplicationInfoForm>
    ) => {
      state.applicationInfoForm._id = action.payload._id;
      state.applicationInfoForm.examId = action.payload.examId;
      state.applicationInfoForm.name1 = action.payload.name1;
      state.applicationInfoForm.name2 = action.payload.name2;
      state.applicationInfoForm.profilePic = action.payload.profilePic;
      state.applicationInfoForm.address = action.payload.address;
      state.applicationInfoForm.dateOfBirth = action.payload.dateOfBirth;
      state.applicationInfoForm.department = action.payload.department;
      state.applicationInfoForm.dateOfGraduation =
        action.payload.dateOfGraduation;
      state.applicationInfoForm.nameOfHighSchool =
        action.payload.nameOfHighSchool;
      state.applicationInfoForm.department = action.payload.department;
      state.applicationInfoForm.additionalFieldsData =
        action.payload.additionalFieldsData.map((x) => {
          return { _id: x.fieldId, values: x.values };
        });
    },
    clearStudentApplicationForm: (state) => {
      state.applicationForm._id = "";
      state.applicationForm.isName1 = true;
      state.applicationForm.isName2 = true;
      state.applicationForm.isAddress = true;
      state.applicationForm.isNameOfHighSchool = true;
      state.applicationForm.isDepartment = true;
      state.applicationForm.isDateOfGraduation = true;
      state.applicationForm.isDateOfBirth = true;
      state.applicationForm.isProfilePic = true;
      state.applicationForm.registrationAdditionalFields = [];
    },
    setApplicationInfoFormLoadFromUserProfile: (
      state,
      action: PayloadAction<{ data: IStudent; id: string }>
    ) => {
      state.applicationInfoForm.address = action.payload.data?.address;
      state.applicationInfoForm.name1 = action.payload.data.name1;
      state.applicationInfoForm.name2 = action.payload.data.name2;
      state.applicationInfoForm.department = action.payload.data.department;
      state.applicationInfoForm.dateOfBirth = action.payload.data.dob;
      state.applicationInfoForm.dateOfGraduation =
        action.payload.data.yearAndDateOfPass;
      state.applicationInfoForm.profilePic = action.payload.data.profilePic;
      state.applicationInfoForm.nameOfHighSchool =
        action.payload.data.nameOfHighSchool;
      state.applicationInfoForm.examId = action.payload.id;
    },

    removeFiles: (state, action: PayloadAction<{ index: number }>) => {
      state.applicationInfoForm.additionalFieldsData.splice(
        action.payload.index,
        1
      );
    },
    updateApplicationInfoFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `applicationInfoForm.${action.payload.key}`,
        action.payload.value
      );
    },
    doUpdateApplicationInfoForm: (
      state,
      action: PayloadAction<{ id: string; callback: (id: string) => void }>
    ) => {
      state.buttonLoading = true;
    },
    doGetApplicationInfoForm: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    updateApplicationInfoFormAdditionalFieldsValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const index = state.applicationInfoForm.additionalFieldsData.findIndex(
        (x) => x._id == action.payload.key
      );
      if (index > -1) {
        set(
          state,
          `applicationInfoForm.additionalFieldsData[${index}].values`,
          action.payload.value
        );
      } else {
        state.applicationInfoForm.additionalFieldsData.push({
          _id: action.payload.key,
          values: action.payload.value,
        });
      }
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
