import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.universitySettings) {
    return state.universitySettings;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectPasswordForm = createSelector(
  [selectDomain],
  (state) => state.passwordForm
);
export const selectBasicDetailsForm = createSelector(
  [selectDomain],
  (state) => state.basicDetails
);
export const selectEmailForm = createSelector(
  [selectDomain],
  (state) => state.emailForm
);
export const selectPrivacyPolicyForm = createSelector(
  [selectDomain],
  (state) => state.privacyPolicyForm
);
