import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";
import { IStudent } from "./types";

export const useClinicSlice = createSlice({
  name: "studentAuth",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setFileUpload: (state, action: PayloadAction<boolean>) => {
      state.fileUpload = action.payload;
    },
    toggleIsEditable: (state) => {
      state.isEditable = !state.isEditable;
    },

    /******** */
    doGetStudentProfile: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.loading = true;
    },

    setStudentData: (state, action: PayloadAction<IStudent>) => {
      state.studentRegister = action.payload;
    },
    /******** */

    updateLoginFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `studentLogin.${action.payload.key}`, action.payload.value);
    },
    clearLoginFrom: (state) => {
      state.studentLogin.email = "";
      state.studentLogin.password = "";
      state.studentLogin.seoTitle = "";
    },
    doLogin: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},

    //student register
    updateRegisterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `studentRegister.${action.payload.key}`, action.payload.value);
    },
    clearStudentRegisterFrom: (state) => {
      state.studentRegister._id = "";
      state.studentRegister.seoTitle = "";
      state.studentRegister.name1 = "";
      state.studentRegister.name2 = "";
      state.studentRegister.email = "";
      state.studentRegister.confirmEmail = "";
      state.studentRegister.password = "";
      state.studentRegister.address = "";
      state.studentRegister.mobileNumber = "";
      state.studentRegister.dob = "";
      state.studentRegister.yearAndDateOfPass = "";
      state.studentRegister.nameOfHighSchool = "";
      state.studentRegister.department = "";
      state.studentRegister.profilePic = "";
      state.studentRegister.graduated = false;
    },
    doStudentRegister: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},
    doUpdateStudent: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},
    //forgot password
    updateForgotPasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `forgotPassword.${action.payload.key}`, action.payload.value);
    },
    clearForgotPasswordFrom: (state) => {
      state.forgotPassword.email = "";
      state.forgotPassword.password = "";
      state.forgotPassword.accessToken = "";
      state.forgotPassword.confirmPassword = "";
      state.forgotPassword.seoTitle = "";
    },
    doSendEmail: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdatePassword: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadImage: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: () => void;
      }>
    ) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
