export interface IUserData {
  _id: string;
  name: string;
  email: string;
  status: ENUM_USER_STATUS;
  seoTitle: string;
  type: string;
}
export enum ENUM_USER_STATUS {
  ACTIVE = 1,
  INACTIVE = 2,
  DELETED = 3,
}
export interface LoginInterface {
  email: string;
  password: string;
  seoTitle?: string;
}
export interface IUBasicDetails {
  _id: string;
  title: string;
  description: string;
}

export interface AuthState {
  loginForm: LoginInterface;
  userData: IUserData;
  loading: boolean;
  token: string | null;
  isLogin: boolean;
  isToken: string | null;

  universityBasic: IUBasicDetails;
  detailsLoading: boolean;
  buttonLoading: boolean;
}

export type InitialState = AuthState;
