import { AuthState, ENUM_USER_STATUS } from ".";

export const initialState: AuthState = {
  loginForm: {
    email: "",
    password: "",
  },
  userData: {
    _id: "",
    name: "",
    email: "",
    status: ENUM_USER_STATUS.ACTIVE,
    seoTitle: "",
    type: "",
  },
  loading: false,
  token: null,
  isLogin: false,
  isToken: null,
  universityBasic: {
    _id: "",
    title: "",
    description: "",
  },
  detailsLoading: false,
  buttonLoading: false,
};
