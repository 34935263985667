import { DateTime } from "luxon";
import { Application_ExaminationState } from ".";

export const initialState: Application_ExaminationState = {
  loading: false,
  isEditable: false,
  applicationInformation: {
    _id: "",
    isName1: true,
    isName2: true,
    isAddress: true,
    isNameOfHighSchool: true,
    isDepartment: true,
    isDateOfGraduation: true,
    isDateOfBirth: true,
    registrationAdditionalFields: [],
    isProfilePic: true,
  },
  examDetailsInformation: {
    _id: "",
    createdAt: "",
    updatedAt: "",
    university: "",
    title: "",
    examTestPoint: false,
    isPublished: false,
    isActive: false,
    postalAddress: "",
    feeLastDate: "",
    feeAmount: 0,
    feeRemark: "",
    remark: "",
    examDate: "",
    examPlace: "",
    examDateRemark: "",
    examResultDate: "",
    examResultRemark: "",
    examPassContent: "",
    examFailContent: "",
    isExamSpecialStudentContent: false,
    examSpecialStudentContent: "",
    examSpecialStudentPassContent: "",
    examSpecialStudentFailContent: "",
    examResultPublishDate: "",
    examResultPublishTime: DateTime.now(),
    offset: "",
    minTestResult: 0,
    enrolmentContent: "",
    enrolmentRemark: "",
    hallTicketNumPrefix: "",
  },
  isSelectedIndex: 0,
};
