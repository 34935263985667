import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.studentExamUploadDoc) {
    return state.studentExamUploadDoc;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectUploadDocForm = createSelector(
  [selectDomain],
  (state) => state.uploadDocForm
);

export const selectUploadDocumentFields = createSelector(
  [selectDomain],
  (state) => state.uploadDocumentFields
);

export const selectAddressFields = createSelector(
  [selectDomain],
  (state) => state.schoolAddressFields
);
export const selectPostalAddress= createSelector(
  [selectDomain],
  (state) => state.postalAddress
);
