import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { IBasicDetails, IPrivacyPolicy } from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "universitySettings",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    //password update start
    updatePasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `passwordForm.${action.payload.key}`, action.payload.value);
    },
    clearPasswordFrom: (state) => {
      state.passwordForm.oldPassword = "";
      state.passwordForm.newPassword = "";
      state.passwordForm.newConfirmPassword = "";
    },
    doUpdatePassword: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},

    //password update end
    //email update start
    updateEmailFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `emailForm.${action.payload.key}`, action.payload.value);
    },
    clearEmailFrom: (state) => {
      state.emailForm.newEmail = "";
      state.emailForm.currentEmail = "";
      state.emailForm.newConfirmEmail = "";
    },
    doEmailUpdate: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},
    //email update end
    //Privacy policy update start
    updatePrivacyFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `privacyPolicyForm.${action.payload.key}`, action.payload.value);
    },
    clearPrivacyFrom: (state) => {
      state.privacyPolicyForm.privacyPolicy = "";
    },
    doGetPrivacyPolicy: (state) => {},
    doPrivacyPolicyUpdate: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},
    setPrivacyPolicyData: (state, action: PayloadAction<IPrivacyPolicy>) => {
      state.privacyPolicyForm = action.payload;
    },
    //Privacy policy update end

    //basic details start
    doGetBasicDetails: (state) => {},

    setBasicDetailsData: (state, action: PayloadAction<IBasicDetails>) => {
      state.basicDetails = action.payload;
    },
    updateBasicDetailsFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `basicDetails.${action.payload.key}`, action.payload.value);
    },
    clearBasicDetailsFrom: (state) => {
      state.basicDetails._id = "";
      state.basicDetails.title = "";
      state.basicDetails.description = "";
      state.basicDetails.address = "";
    },
    doBasicDetailsUpdate: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    //password update end
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
