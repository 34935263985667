import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get } from "../../../../utils/request";

export function* doGetRegistrationDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/registration/data/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setRegistrationDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetSubmissionMailingDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/submission-mailing/data/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setSubmissionMailingDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetExamPaymentDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `university/exam/get/payment/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setPaymentDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* UniversityExamDetailsRepoSaga() {
  yield takeLatest(
    actions.doGetRegistrationDetails,
    doGetRegistrationDetailsRequest
  );
  yield takeLatest(
    actions.doGetSubmissionMailingDetails,
    doGetSubmissionMailingDetailsRequest
  );
  yield takeLatest(
    actions.doGetExamPaymentDetails,
    doGetExamPaymentDetailsRequest
  );
}
