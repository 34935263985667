import { DayOfExamAndPassFailState } from ".";

export const initialState: DayOfExamAndPassFailState = {
  loading: false,
  buttonLoading: false,
  examDayForm: {
    _id: "",
    examDate: "",
    examPlace: "",
    examDateRemark: "",
  },
  passFailData: {
    _id: "",
    title: "",
    marks: 0,
    resultContent: "",
  },
  admissionDetails: {
    _id: "",
    enrolmentContent: "",
    enrolmentRemark: "",
    admissionCompleted: false,
  },
};
