import { PaymentTypeEnum, StudentPaymentState } from ".";

export const initialState: StudentPaymentState = {
  loading: false,
  buttonLoading: false,
  paymentDetails: {
    _id: "",
    feeLastDate: "",
    title: "",
    remark: "",
    amount: 0,
  },
  createPayment: {
    email: "",
    phoneNo: "",
    paymentMethod: PaymentTypeEnum.NON,
  },
  paymentKeyData: "",
  stripeShow: false,
  paymentConfirm: false,
};
