import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import { selectLoginForm } from "./selector";
import { LoginInterface } from "./types";
import CatchBlockFunction from "../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, post } from "../../utils/request";
import { loginValidation } from "../../Components/Common/Validations";

export function* doLoginRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: LoginInterface = yield select(selectLoginForm);

  const isValid = loginValidation(form);
  if (!isValid) {
    return;
  }
  let data = {
    email: form?.email,
    password: btoa(form?.password),
  };

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      post,
      `auth/university/login`,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actions.setIsLogin(true));
    yield put(actions.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback(response.data.data.accessToken));
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetMeProfileRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.token) {
    try {
      yield put(actions.setLoading(true));
      const response: AxiosResponse = yield call(get, `auth/user/me`);
      if (response && !response.data) {
        toast.error(response.data.message);
        return;
      }
      yield put(actions.setProfileData(response.data.data));
      yield put(actions.setIsLogin(true));
      yield put(actions.setLoading(true));
      yield call(action?.payload?.callback());
    } catch (error: any) {
      yield put(actions.setLoading(false));
      if (error?.response?.data?.statusCode == 404) {
        yield put(actions.clearLocalStorage());
        return;
      } else {
        CatchBlockFunction(error);
      }
    }
  } else {
    yield put(actions.setLoading(false));
    yield put(actions.setIsLogin(false));
  }
}
export function* doGetUniversityDetailsRequest(action: {
  payload: { title: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setDetailsLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `public/university/get/${action.payload.title}`
    );

    yield put(actions.setDetailsLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setUniversityDetails(response.data.data));
  } catch (error: any) {
    yield put(actions.setDetailsLoading(false));
    if (error?.response?.data?.statusCode == 5140) {
      toast.error(error?.response?.data?.message);
      setTimeout(() => {
        window.location.replace("/");
      }, 2500);
    }
    CatchBlockFunction(error);
  }
}

export function* AuthRepoSaga() {
  yield takeLatest(actions.doLogin, doLoginRequest);
  yield takeLatest(actions.doGetProfile, doGetMeProfileRequest);

  // university title
  yield takeLatest(
    actions.doGetUniversityDetails,
    doGetUniversityDetailsRequest
  );
}
