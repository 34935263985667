import { configureStore } from "@reduxjs/toolkit";
import { createInjectorsEnhancer, forceReducerReload } from "redux-injectors";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { createReducer } from "./reducers";
const IS_DEVELOPMENT = import.meta.env.VITE_APP_LOG == "true";

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  let middlewares: any = [sagaMiddleware];

  // LOG ONLY IN DEVELOPMENT/STAGING PRODUCTION OPTIMIZATIONS
  if (IS_DEVELOPMENT) {
    middlewares = [...middlewares];
  }
  const myEnhancer = [
    createInjectorsEnhancer({
      //@ts-ignore
      createReducer,
      runSaga,
    }),
  ];
  if (IS_DEVELOPMENT) {
    middlewares.push(
      createLogger({
        collapsed: true,
        duration: true,
        timestamp: true,
      })
    );
  }

  const store = configureStore({
    //@ts-ignore
    reducer: createReducer(),
    //@ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: [],
          // Ignore these field paths in all actions
          ignoredActionPaths: ["meta.arg", "payload.callback", "payload.data"],
          // Ignore these paths in the state
        },
      }).concat(middlewares),

    devTools: false,
    //@ts-ignore
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(myEnhancer);
    },
  });

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */

  if (import.meta.hot) {
    //@ts-ignore
    import.meta.hot.accept("./reducers", () => {
      forceReducerReload(store);
    });
  }

  return store;
}
