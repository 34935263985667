import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, post, putType } from "../../../utils/request";
import {
  selectAddressFields,
  selectUploadDocForm,
  selectUploadDocumentFields,
} from "./selector";
import { IExamUploadForm } from "./types";
import {
  ENUM_ADDITIONAL_FIELD_TYPE,
  ISchoolAddress,
  IUploadDocument,
} from "../../UniversitySection/UniversityExam/Submissions&Mailings/types";
import { t } from "i18next";
export function* doGetUploadDocFormFieldRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/submission-mailings/fields/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamUploadDocFormFields(response.data.data[0]));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* uploadDocumentRequest(action: {
  payload: { fileName: string; callback: any };
}) {
  try {
    const response: AxiosResponse = yield call(post, `/student/signed-url`, {
      fileName: action.payload.fileName,
    });
    if (!response.status) {
      toast.error(response.data.message);
    }
    yield call(
      action?.payload?.callback(
        response.data.data.signedUrl,
        response.data.data.fileNameWithPrefix
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* updateMailingAndSubmissionRequest(action: {
  payload: { id: string };
}) {
  yield delay(500);
  const form: IExamUploadForm = yield select(selectUploadDocForm);

  const uploadDocumentFields: IUploadDocument[] = yield select(
    selectUploadDocumentFields
  );
  const addressFields: ISchoolAddress[] = yield select(selectAddressFields);
  let isError = false;
  for (let index = 0; index < uploadDocumentFields.length; index++) {
    const element = uploadDocumentFields[index];

    if (
      form.uploadDocumentFieldsData.findIndex((x) => x._id == element._id) == -1
    ) {
      toast.error(
        `${t("SUBMISSION_AND_MAILING.ERRORS.ADDITIONAL_FILED", {
          name: element.title,
        })}`
      );

      isError = true;
    } else if (
      !form.uploadDocumentFieldsData[
        form.uploadDocumentFieldsData.findIndex((x) => x._id == element._id)
      ].values
    ) {
      toast.error(
        `${t("SUBMISSION_AND_MAILING.ERRORS.ADDITIONAL_FILED", {
          name: element.title,
        })}`
      );

      isError = true;
    } else if (
      form.uploadDocumentFieldsData[
        form.uploadDocumentFieldsData.findIndex((x) => x._id == element._id)
      ].values.length == 0
    ) {
      toast.error(
        `${t("SUBMISSION_AND_MAILING.ERRORS.ADDITIONAL_FILED", {
          name: element.title,
        })}`
      );

      isError = true;
    }
  }

  for (let index = 0; index < addressFields.length; index++) {
    const element = addressFields[index];
    if (element?.type !== ENUM_ADDITIONAL_FIELD_TYPE.NONE_SELECTED) {
      if (
        form.schoolAddressFieldsData.findIndex((x) => x._id == element._id) ==
        -1
      ) {
        toast.error(
          `${t("SUBMISSION_AND_MAILING.ERRORS.ADDITIONAL_FILED", {
            name: element.title,
          })}`
        );

        isError = true;
      } else if (
        !form.schoolAddressFieldsData[
          form.schoolAddressFieldsData.findIndex((x) => x._id == element._id)
        ].values
      ) {
        toast.error(
          `${t("SUBMISSION_AND_MAILING.ERRORS.ADDITIONAL_FILED", {
            name: element.title,
          })}`
        );

        isError = true;
      } else if (
        form.schoolAddressFieldsData[
          form.schoolAddressFieldsData.findIndex((x) => x._id == element._id)
        ].values.length == 0
      ) {
        toast.error(
          `${t("SUBMISSION_AND_MAILING.ERRORS.ADDITIONAL_FILED", {
            name: element.title,
          })}`
        );
        isError = true;
      }
    }
  }
  if (isError) {
    yield put(actions.setLoading(false));
    return;
  }

  try {
    const response: AxiosResponse = yield call(
      putType,
      `student/exam/update/submission-mailings/data/${action.payload.id}`,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    //@ts-ignore
    yield call(action.payload.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* getMailingSubmissionRequest(action: { payload: string }) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/submission-mailing/data/${action.payload}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setMailingSubmissionForm(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* StudentExamUploadDocRepoSaga() {
  yield takeLatest(
    actions.doGetUploadDocFormField,
    doGetUploadDocFormFieldRequest
  );
  yield takeEvery(actions.getSignedUrl, uploadDocumentRequest);

  yield takeLatest(
    actions.doUpdateMailingSubmissionForm,
    updateMailingAndSubmissionRequest
  );

  yield takeLatest(
    actions.doGetMailingSubmissionFrom,
    getMailingSubmissionRequest
  );
}
