import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { IUBasicDetails, IUserData } from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.detailsLoading = action.payload;
    },
    setIsToken: (state, action: PayloadAction<string | null>) => {
      state.isToken = action.payload;
    },
    updateLoginFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `loginForm.${action.payload.key}`, action.payload.value);
    },
    clearLoginFrom: (state) => {
      state.loginForm.email = "";
      state.loginForm.password = "";
    },
    doLogin: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => {},
    doLogOut: (state) => {
      localStorage.clear();
    },
    clearLocalStorage: (state) => {
      localStorage.clear();
    },

    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },

    doGetProfile: (
      state,
      action: PayloadAction<{
        token: string;
        callback: () => void;
      }>
    ) => {},

    setProfileData: (state, action: PayloadAction<IUserData>) => {
      state.userData = action.payload;
      localStorage.setItem("isRole", action.payload.type);
    },

    // university title
    doGetUniversityDetails: (
      state,
      action: PayloadAction<{
        title: string;
      }>
    ) => {
      state.detailsLoading = true;
    },

    setUniversityDetails: (state, action: PayloadAction<IUBasicDetails>) => {
      state.universityBasic = action.payload;
    },
    doLogout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("isRole");
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
