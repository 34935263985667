import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";
import {
  ENUM_ADDITIONAL_FIELD_TYPE,
  ENUM_FILE_TYPE,
  IHallTicket,
  IPostalAddress,
  ISchoolAddress,
  ISchoolAddressData,
  ITestName,
  IUploadDocument,
  IUploadDocumentData,
} from "./types";

export const useClinicSlice = createSlice({
  name: "submissionsMailings",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsEditable: (state, action: PayloadAction<boolean>) => {
      state.isEditable = action.payload;
    },
    //Test Name section start

    updateTestNameFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `testNameForm.${action.payload.key}`, action.payload.value);
    },
    clearTestNameForm: (state) => {
      state.testNameForm = null;
    },
    doCreateTestName: (
      state,
      action: PayloadAction<{ callback: (id: string) => void }>
    ) => {},
    doUpdateTestName: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetTestName: (state, action: PayloadAction<{ id: string }>) => {
      state.isEditable = true;
    },
    setTestNameData: (state, action: PayloadAction<ITestName | null>) => {
      state.testNameForm = action.payload;
    },
    //Test Name section end
    //postal address start

    updatePostalAddressFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `postalAddressForm.${action.payload.key}`,
        action.payload.value
      );
    },
    clearPostalAddressForm: (state) => {
      state.postalAddressForm.postalAddress = "";
    },

    doUpdatePostalAddress: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    doGetPostalAddress: (state, action: PayloadAction<{ id: string }>) => {
      state.isEditable = true;
    },
    setPostalAddressData: (state, action: PayloadAction<IPostalAddress>) => {
      state.postalAddressForm = action.payload;
    },
    //postal address end
    /* ****** */
    updateSchoolAddressFormValue: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      set(
        state,
        `schoolAddressData.schoolAddress[${action.payload.index}].${action.payload.key}`,
        action.payload.value
      );
      if (action.payload.key == "type") {
        state.schoolAddressData.schoolAddress[action.payload.index].options =
          [];
        switch (action.payload.value as ENUM_ADDITIONAL_FIELD_TYPE) {
          case ENUM_ADDITIONAL_FIELD_TYPE.MULTI_SELECT:
            state.schoolAddressData.schoolAddress[
              action.payload.index
            ].options?.push("");
            break;
          case ENUM_ADDITIONAL_FIELD_TYPE.SINGLE_SELECT:
            state.schoolAddressData.schoolAddress[
              action.payload.index
            ].options?.push("");

            break;
          case ENUM_ADDITIONAL_FIELD_TYPE.SELECT_YES_NO:
            state.schoolAddressData.schoolAddress[
              action.payload.index
            ].options?.push("Yes");
            state.schoolAddressData?.schoolAddress[
              action.payload.index
            ].options?.push("No");
            break;
          case ENUM_ADDITIONAL_FIELD_TYPE.FILE_SELECT:
            state.schoolAddressData.schoolAddress[
              action.payload.index
            ].fileSelectFileType = ENUM_FILE_TYPE.PDF;
            state.schoolAddressData.schoolAddress[
              action.payload.index
            ].maxNoOfFiles = 1;
            break;
          default:
            break;
        }
      }
    },

    updateSchoolAddressFormOptionValue: (
      state,
      action: PayloadAction<{
        index: number;
        innerIndex: number;
        key: string;
        value: any;
      }>
    ) => {
      set(
        state,
        `schoolAddressData.schoolAddress[${action.payload.index}].${action.payload.key}[${action.payload.innerIndex}]`,
        action.payload.value
      );
    },
    removeSchoolAddressFormOptionValue: (
      state,
      action: PayloadAction<{ index: number; innerIndex: number }>
    ) => {
      state.schoolAddressData.schoolAddress[
        action.payload.index
      ].options?.splice(action.payload.innerIndex, 1);
    },
    addSchoolAddressFormOptionValue: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      state.schoolAddressData.schoolAddress[action.payload.index].options?.push(
        ""
      );
    },
    addSchoolAddressFormValue: (
      state,
      action: PayloadAction<ISchoolAddress>
    ) => {
      state.schoolAddressData.schoolAddress.push(action.payload);
    },
    clearSchoolAddressForm: (state) => {
      state.schoolAddressData.schoolAddressForm._id = "";
      state.schoolAddressData.schoolAddressForm.description = "";
      state.schoolAddressData.schoolAddressForm.isDeleted = false;
      state.schoolAddressData.schoolAddressForm.fileSelectFileType =
        ENUM_FILE_TYPE.PDF;
      state.schoolAddressData.schoolAddressForm.options = [];
      state.schoolAddressData.schoolAddressForm.title = "";
      state.schoolAddressData.schoolAddressForm.type =
        ENUM_ADDITIONAL_FIELD_TYPE.NONE_SELECTED;
    },

    doRemoveSchoolAddressData: (
      state,
      action: PayloadAction<{ index: number; id: string }>
    ) => {
      if (action.payload.id.length > 0) {
        state.schoolAddressData.schoolAddress[action.payload.index].isDeleted =
          true;
      } else {
        state.schoolAddressData.schoolAddress.splice(action.payload.index, 1);
      }
    },
    doUpdateSchoolAddress: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    doGetSchoolAddress: (state, action: PayloadAction<{ id: string }>) => {
      state.isEditable = true;
    },
    setSchoolAddressData: (
      state,
      action: PayloadAction<ISchoolAddressData>
    ) => {
      state.schoolAddressData = action.payload;
    },
    /* ****** */
    /* ****** */
    updateUploadDocumentFormValue: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      set(
        state,
        `uploadDocument.uploadDocumentFields[${action.payload.index}].${action.payload.key}`,
        action.payload.value
      );
    },

    addUploadDocumentFormValue: (
      state,
      action: PayloadAction<IUploadDocument>
    ) => {
      state.uploadDocument.uploadDocumentFields.push(action.payload);
    },

    clearUploadDocument: (state) => {
      state.uploadDocument.uploadDocumentFields = [];
    },

    doRemoveUploadDocumentData: (
      state,
      action: PayloadAction<{ index: number; id: string }>
    ) => {
      if (action.payload.id?.length > 0) {
        state.uploadDocument.uploadDocumentFields[
          action.payload.index
        ].isDeleted = true;
      } else {
        state.uploadDocument.uploadDocumentFields.splice(
          action.payload.index,
          1
        );
      }
    },
    doUpdateUploadDocument: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    doGetUploadDocument: (state, action: PayloadAction<{ id: string }>) => {
      state.isEditable = true;
    },
    setSUploadDocumentData: (
      state,
      action: PayloadAction<IUploadDocumentData>
    ) => {
      state.uploadDocument = action.payload;
    },

    /* ****** */
    /*******Hall Ticket */ doUpdateHallTicket: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetHallTicket: (state) => {
      state.isEditable = true;
    },
    setHallTicketData: (state, action: PayloadAction<string>) => {
      state.hallTicket.remark = action.payload;
    },
    clearHallTicketForm: (state) => {
      state.hallTicket.remark = "";
      state.hallTicket._id = "";
    },
    updateHallTicketFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `hallTicket.${action.payload.key}`, action.payload.value);
    },
    /******* */
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
