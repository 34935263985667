import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.studentAuth) {
    return state.studentAuth;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);

export const selectStudentLoginForm = createSelector(
  [selectDomain],
  (state) => state.studentLogin
);
export const selectStudentRegisterForm = createSelector(
  [selectDomain],
  (state) => state.studentRegister
);
export const selectForgotPasswordForm = createSelector(
  [selectDomain],
  (state) => state.forgotPassword
);
export const selectFileUpload = createSelector(
  [selectDomain],
  (state) => state.fileUpload
);
export const selectIsEditableButton = createSelector(
  [selectDomain],
  (state) => state.isEditable
);
