import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.studentPayment) {
    return state.studentPayment;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectPaymentDetails = createSelector(
  [selectDomain],
  (state) => state.paymentDetails
);
export const selectCreatePaymentForm = createSelector(
  [selectDomain],
  (state) => state.createPayment
);
export const selectPaymentKeyData = createSelector(
  [selectDomain],
  (state) => state.paymentKeyData
);
export const selectStripeShowStatus = createSelector(
  [selectDomain],
  (state) => state.stripeShow
);
export const selectPaymentConfirm = createSelector(
  [selectDomain],
  (state) => state.paymentConfirm
);
