import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.studentExamForm) {
    return state.studentExamForm;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectExamApplicationForm = createSelector(
  [selectDomain],
  (state) => state.applicationForm
);
export const selectExamApplicationInfoForm = createSelector(
  [selectDomain],
  (state) => state.applicationInfoForm
);
export const selectExamName = createSelector(
  [selectDomain],
  (state) => state.examName
);
export const selectValidations = createSelector(
  [selectDomain],
  (state) => state.validationData
);
export const selectVLoading = createSelector(
  [selectDomain],
  (state) => state.vLoading
);
