import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get } from "../../../utils/request";
import {
  selectAppliedPageNo,
  selectAppliedPageSize,
  selectAppliedSortColumn,
  selectAppliedSortDir,
  selectPageNo,
  selectPageSize,
  selectSortColumn,
  selectSortDir,
} from "./selector";

export function* doGetExamListRequest() {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const orderBy: string = yield select(selectSortColumn);
    const orderDir: string = yield select(selectSortDir);
    const response: AxiosResponse = yield call(
      get,
      `student/exam/list?pageNo=${pageNo}&pageSize=${pageSize}${
        orderBy.length > 0 ? "&orderBy=" + orderBy : ""
      }${orderDir.length > 0 ? "&orderDirection=" + orderDir : ""}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.totalPages : 0
      )
    );
    yield put(actions.setStudentExamList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetStudentAppliedExamListRequest() {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const pageNo: number = yield select(selectAppliedPageNo);
    const pageSize: number = yield select(selectAppliedPageSize);
    const SortColumn: string = yield select(selectAppliedSortColumn);
    const SortDir: string = yield select(selectAppliedSortDir);
    const response: AxiosResponse = yield call(
      get,
      `student/my/exams?pageNo=${pageNo}&pageSize=${pageSize}${
        SortColumn.length > 0 ? "&orderBy=" + SortColumn : ""
      }${SortDir.length > 0 ? "&orderDirection=" + SortDir : ""}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(
      actions.setAppliedTotalRow(
        response.data.data.meta ? response.data.data.meta.totalPages : 0
      )
    );
    yield put(actions.setStudentAppliedExamList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* StudentExamListRepoSaga() {
  yield takeLatest(actions.doGetStudentExamList, doGetExamListRequest);
  yield takeLatest(
    actions.doGetStudentAppliedExamList,
    doGetStudentAppliedExamListRequest
  );
}
