import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as actionsAuth } from "../slice";
import { AxiosResponse } from "axios";
import {
  selectForgotPasswordForm,
  selectStudentLoginForm,
  selectStudentRegisterForm,
} from "./selector";
import { IStudent, IStudentForgotPassword } from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, patch, post, uploadImg } from "../../../utils/request";
import {
  loginValidation,
  passwordUpdateValidation,
  studentRegisterValidation,
} from "../../../Components/Common/Validations";
import { LoginInterface } from "../types";

export function* doLoginRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: LoginInterface = yield select(selectStudentLoginForm);

  const isValid = loginValidation(form, true);
  if (!isValid) {
    return;
  }
  let data = {
    email: form?.email,
    seoTitle: form?.seoTitle,
    password: btoa(form?.password),
  };

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      post,
      `auth/student/login`,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actionsAuth.setIsLogin(true));
    yield put(actionsAuth.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback(response.data.data.accessToken));
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doStudentRegisterRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: IStudent = yield select(selectStudentRegisterForm);

  const data = {
    seoTitle: form?.seoTitle,
    name1: form?.name1,
    name2: form?.name2,
    email: form?.email,
    confirmEmail: form?.confirmEmail,
    password: btoa(form?.password),
    address: form?.address,
    mobileNumber: form?.mobileNumber,
    dob: form?.dob,
    nameOfHighSchool: form?.nameOfHighSchool,
    yearAndDateOfPass: form?.yearAndDateOfPass,
    department: form?.department,
    profilePic: form?.profilePic,
    graduated: form?.graduated,
  };

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(post, `student/register`, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doSendEmailRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: IStudentForgotPassword = yield select(selectForgotPasswordForm);
  const isValid = passwordUpdateValidation(form, true);
  if (!isValid) {
    return;
  }
  const data = { seoTitle: form.seoTitle, email: form.email };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      post,
      `student/forgot-password`,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdatePasswordRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: IStudentForgotPassword = yield select(selectForgotPasswordForm);
  const isValid = passwordUpdateValidation(form, false);
  if (!isValid) {
    return;
  }
  const data = {
    password: btoa(form.password),
    confirmPassword: btoa(form.confirmPassword),
    accessToken: form.accessToken,
  };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `student/update/password`,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetStudentProfileRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(get, `student/get/profile`);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setStudentData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));

    CatchBlockFunction(error);
  }
} /********* */
export function* FileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(post, `/student/signed-url`, {
      fileName: action.payload.data,
    });
    if (!response.status) {
      toast.error(response.data.message);
    }
    yield call(
      action?.payload?.callback(
        response.data.data.signedUrl,
        response.data.data.fileNameWithPrefix
      )
    );
  } catch (error: any) {
    yield put(actions.setFileUpload(false));
    CatchBlockFunction(error);
  }
}
export function* UploadImageRequest(action: {
  payload: { data: any; signedUrl: string; result: any; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uploadImg,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      toast.error(response.data.message);
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setFileUpload(false));
    CatchBlockFunction(error);
  }
} /******** */
export function* doUpdateStudentRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: IStudent = yield select(selectStudentRegisterForm);
  const isValid = studentRegisterValidation(form, true);
  if (!isValid) {
    return;
  }
  const data = {
    name1: form?.name1,
    name2: form?.name2,
    address: form?.address,
    mobileNumber: form?.mobileNumber,
    dob: form?.dob,
    nameOfHighSchool: form?.nameOfHighSchool,
    yearAndDateOfPass: form?.yearAndDateOfPass,
    department: form?.department,
    profilePic: form?.profilePic,
    graduated: form?.graduated,
  };

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      post,
      `student/update/profile`,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* StudentAuthRepoSaga() {
  yield takeLatest(actions.doGetStudentProfile, doGetStudentProfileRequest);
  yield takeLatest(actions.doLogin, doLoginRequest);
  yield takeLatest(actions.doStudentRegister, doStudentRegisterRequest);
  yield takeLatest(actions.doUpdateStudent, doUpdateStudentRequest);
  yield takeLatest(actions.doSendEmail, doSendEmailRequest);
  yield takeLatest(actions.doUpdatePassword, doUpdatePasswordRequest);

  // upload image
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadImage, UploadImageRequest);
}
