import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { IAdmissionDetails, IExamDayForm, IPassFail } from "./types";

export const useClinicSlice = createSlice({
  name: "examDayAndPassFail",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    doGetStudentDayOfExam: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    setExamDayFormData: (state, action: PayloadAction<IExamDayForm>) => {
      state.examDayForm = action.payload;
    },
    doGetDownloadDoc: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    setExamDownloadData: (state, action: PayloadAction<IExamDayForm>) => {
      state.examDayForm = action.payload;
    },
    doGetPassFailDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    setPassFailData: (state, action: PayloadAction<IPassFail>) => {
      state.passFailData = action.payload;
    },
    doGetAdmissionDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {
      state.loading = true;
    },
    doSubmitAdmission: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setAdmissionDetails: (state, action: PayloadAction<IAdmissionDetails>) => {
      state.admissionDetails = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
