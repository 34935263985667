import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.universityExamList) {
    return state.universityExamList;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectExamList = createSelector(
  [selectDomain],
  (state) => state.examList
);

export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);

export const selectOrderBy = createSelector(
  [selectDomain],
  (state) => state.orderBy
);
export const selectOrderDir = createSelector(
  [selectDomain],
  (state) => state.orderDir
);
export const selectExamStudentList = createSelector(
  [selectDomain],
  (state) => state.studentList
);

export const selectStudentPageNo = createSelector(
  [selectDomain],
  (state) => state.studentPageNo
);
export const selectStudentPageSize = createSelector(
  [selectDomain],
  (state) => state.studentPageSize
);
export const selectStudentTotalPages = createSelector(
  [selectDomain],
  (state) => state.studentTotalPages
);

export const selectStudentOrderBy = createSelector(
  [selectDomain],
  (state) => state.studentOrderBy
);
export const selectStudentOrderDir = createSelector(
  [selectDomain],
  (state) => state.studentOrderDir
);
export const selectConfirmModal = createSelector(
  [selectDomain],
  (state) => state.confirmModal
);
export const selectSelectedId = createSelector(
  [selectDomain],
  (state) => state.selectedId
);
export const selectSelectedIndex = createSelector(
  [selectDomain],
  (state) => state.selectedIndex
);
export const selectSelectedType = createSelector(
  [selectDomain],
  (state) => state.selectedType
);
export const selectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const selectExamPreview = createSelector(
  [selectDomain],
  (state) => state.examPreview
);
