import React from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as AuthSliceKey,
  reducer as AuthReducer,
} from "../../Redux/Auth/slice";
import { AuthRepoSaga } from "../../Redux/Auth/saga";

import {
  sliceKey as UniversitySettingsSliceKey,
  reducer as UniversitySettingsReducer,
} from "../../Redux/UniversitySection/Setting/University/slice";
import { UniversitySettingsRepoSaga } from "../../Redux/UniversitySection/Setting/University/saga";

import {
  sliceKey as Submissions_MailingsSliceKey,
  reducer as Submissions_MailingsReducer,
} from "../../Redux/UniversitySection/UniversityExam/Submissions&Mailings/slice";

import { Submissions_MailingsRepoSaga } from "../../Redux/UniversitySection/UniversityExam/Submissions&Mailings/saga";

import {
  sliceKey as UniversityExamListSliceKey,
  reducer as UniversityExamListReducer,
} from "../../Redux/UniversitySection/ExamList/slice";
import { UniversityExamListRepoSaga } from "../../Redux/UniversitySection/ExamList/saga";

import {
  sliceKey as UniversityApplication_ExaminatioSliceKey,
  reducer as UniversityApplication_ExaminationReducer,
} from "../../Redux/UniversitySection/UniversityExam/Application&Examination/slice";
import { Application_ExaminationRepoSaga } from "../../Redux/UniversitySection/UniversityExam/Application&Examination/saga";

import {
  sliceKey as StudentAuthSliceKey,
  reducer as StudentAuthReducer,
} from "../../Redux/Auth/Student/slice";

import { StudentAuthRepoSaga } from "../../Redux/Auth/Student/saga";

import {
  sliceKey as StudentExamListSliceKey,
  reducer as StudentExamListReducer,
} from "../../Redux/Student/ExamList/slice";

import { StudentExamListRepoSaga } from "../../Redux/Student/ExamList/saga";

import {
  sliceKey as StudentExamApplicationSliceKey,
  reducer as StudentExamApplicationReducer,
} from "../../Redux/Student/ApplicationInput/slice";
import { StudentExamApplicationRepoSaga } from "../../Redux/Student/ApplicationInput/saga";

import {
  sliceKey as StudentExamUploadDocSliceKey,
  reducer as StudentExamUploadDocReducer,
} from "../../Redux/Student/UploadDocument/slice";

import { StudentExamUploadDocRepoSaga } from "../../Redux/Student/UploadDocument/saga";

import {
  sliceKey as ExamDayAndPassFailSliceKey,
  reducer as ExamDayAndPassFailReducer,
} from "../../Redux/Student/DayOfExamAndPassFail/slice";

import { ExamDayAndPassFailRepoSaga } from "../../Redux/Student/DayOfExamAndPassFail/saga";

import {
  sliceKey as StudentPaymentSliceKey,
  reducer as StudentPaymentReducer,
} from "../../Redux/Student/Payment/slice";

import { StudentPaymentRepoSaga } from "../../Redux/Student/Payment/saga";


import {
  sliceKey as UniversityExamDetailsSliceKey,
  reducer as UniversityExamDetailsReducer,
} from "../../Redux/UniversitySection/ExamList/ExamDetails/slice";
import { UniversityExamDetailsRepoSaga } from "../../Redux/UniversitySection/ExamList/ExamDetails/saga";

interface Props {
  children: any;
}

const Index: React.FC<Props> = ({ children }) => {
  useInjectReducer({
    key: AuthSliceKey,
    reducer: AuthReducer,
  });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  /*******Student Auth */
  useInjectReducer({
    key: StudentAuthSliceKey,
    reducer: StudentAuthReducer,
  });
  useInjectSaga({ key: StudentAuthSliceKey, saga: StudentAuthRepoSaga });
  /*******Student Auth */
  //university setting start
  useInjectReducer({
    key: UniversitySettingsSliceKey,
    reducer: UniversitySettingsReducer,
  });
  useInjectSaga({
    key: UniversitySettingsSliceKey,
    saga: UniversitySettingsRepoSaga,
  });
  //university setting end

  //university Submissions Mailings start
  useInjectReducer({
    key: Submissions_MailingsSliceKey,
    reducer: Submissions_MailingsReducer,
  });
  useInjectSaga({
    key: Submissions_MailingsSliceKey,
    saga: Submissions_MailingsRepoSaga,
  });
  //university Submissions Mailings end
  //university Submissions Mailings start
  useInjectReducer({
    key: UniversityApplication_ExaminatioSliceKey,
    reducer: UniversityApplication_ExaminationReducer,
  });
  useInjectSaga({
    key: UniversityApplication_ExaminatioSliceKey,
    saga: Application_ExaminationRepoSaga,
  });
  //university Submissions Mailings end
  //university Exam list start
  useInjectReducer({
    key: UniversityExamListSliceKey,
    reducer: UniversityExamListReducer,
  });
  useInjectSaga({
    key: UniversityExamListSliceKey,
    saga: UniversityExamListRepoSaga,
  });
  //university Exam list end
  //student Exam list start
  useInjectReducer({
    key: StudentExamListSliceKey,
    reducer: StudentExamListReducer,
  });
  useInjectSaga({
    key: StudentExamListSliceKey,
    saga: StudentExamListRepoSaga,
  });
  //student Exam list end
  //Student Exam Application start
  useInjectReducer({
    key: StudentExamApplicationSliceKey,
    reducer: StudentExamApplicationReducer,
  });
  useInjectSaga({
    key: StudentExamApplicationSliceKey,
    saga: StudentExamApplicationRepoSaga,
  });
  // Student Exam Application end
  // Student Exam Upload Doc start
  useInjectReducer({
    key: StudentExamUploadDocSliceKey,
    reducer: StudentExamUploadDocReducer,
  });
  useInjectSaga({
    key: StudentExamUploadDocSliceKey,
    saga: StudentExamUploadDocRepoSaga,
  });
  // Student Exam Upload Doc end
  // Student Exam Upload Doc start
  useInjectReducer({
    key: ExamDayAndPassFailSliceKey,
    reducer: ExamDayAndPassFailReducer,
  });
  useInjectSaga({
    key: ExamDayAndPassFailSliceKey,
    saga: ExamDayAndPassFailRepoSaga,
  });
  // Student Exam Upload Doc end
  // Student Exam Upload Doc start
  useInjectReducer({
    key: StudentPaymentSliceKey,
    reducer: StudentPaymentReducer,
  });
  useInjectSaga({
    key: StudentPaymentSliceKey,
    saga: StudentPaymentRepoSaga,
  });
  // Student Exam Upload Doc end
  // Student Exam Upload Doc start
  useInjectReducer({
    key: UniversityExamDetailsSliceKey,
    reducer: UniversityExamDetailsReducer,
  });
  useInjectSaga({
    key: UniversityExamDetailsSliceKey,
    saga: UniversityExamDetailsRepoSaga,
  });
  // Student Exam Upload Doc end

  return children;
};

export default Index;
