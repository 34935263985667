import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.submissionsMailings) {
    return state.submissionsMailings;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectIsEditable = createSelector(
  [selectDomain],
  (state) => state.isEditable
);
export const selectTestNameForm = createSelector(
  [selectDomain],
  (state) => state.testNameForm
);
export const selectPostalAddressForm = createSelector(
  [selectDomain],
  (state) => state.postalAddressForm
);
export const selectSchoolAddressData = createSelector(
  [selectDomain],
  (state) => state.schoolAddressData
);
export const selectUploadDocument = createSelector(
  [selectDomain],
  (state) => state.uploadDocument
);
export const selectHallTicketForm = createSelector(
  [selectDomain],
  (state) => state.hallTicket
);

