import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, post } from "../../../utils/request";
import { selectCreatePaymentForm } from "./selector";
import { IPayment } from "./types";

export function* doGetExamPriceRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/fee-detail/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setPaymentDetails(response.data.data));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetPaymentDetailsRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(get, `payment/payment-data`);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setPaymentKeyData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doCreatePaymentRequest(action: {
  payload: { id: string; address: any; callback: any };
}) {
  yield delay(500);
  const form: IPayment = yield select(selectCreatePaymentForm);

  const data = {
    examId: action.payload.id,
    accountHolderName: action.payload.address?.name,
    email: form?.email,
    phoneNo: form?.phoneNo,
    city: action.payload?.address?.address?.city,
    state: action.payload?.address?.address?.state,
    line1: action.payload?.address?.address?.line1,
    line2: action.payload?.address?.address?.line2,
    postalCode: action.payload?.address?.address?.postal_code,
    country: action.payload?.address?.address?.country,
    paymentMethod: form.paymentMethod,
  };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      post,
      `payment/create-payment`,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdatePaymentRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(get, `payment/update/payment`);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setPaymentKeyData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* StudentPaymentRepoSaga() {
  yield takeLatest(actions.doGetPaymentDetails, doGetPaymentDetailsRequest);
  yield takeLatest(actions.doCreatePayment, doCreatePaymentRequest);
  yield takeLatest(actions.doGetExamPrice, doGetExamPriceRequest);
  yield takeLatest(actions.doUpdatePayment, doUpdatePaymentRequest);
}
