import {
  ENUM_ADDITIONAL_FIELD_TYPE,
  ENUM_FILE_TYPE,
  Submissions_MailingsState,
} from ".";

export const initialState: Submissions_MailingsState = {
  loading: false,
  isEditable: false,
  testNameForm: null,
  postalAddressForm: {
    postalAddress: "",
  },
  schoolAddressData: {
    _id: "",
    schoolAddress: [],
    schoolAddressForm: {
      _id: "",
      title: "",
      description: "",
      type: ENUM_ADDITIONAL_FIELD_TYPE.NONE_SELECTED,
      options: [],
      fileSelectFileType: ENUM_FILE_TYPE.PDF,
      isDeleted: false,
      maxNoOfFiles: 1,
    },
  },
  uploadDocument: {
    _id: "",
    uploadDocumentFields: [],
    uploadDocumentForm: {
      _id: "",
      title: "",
      maxNoOfFiles: 1,
      fileSelectFileType: ENUM_FILE_TYPE.PDF,
      isDeleted: false,
      description: "",
    },
  },
  hallTicket: {
    _id: "",
    remark: "",
  },
};
