import { call, delay, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import { toast } from "sonner";
import { get, patch } from "../../../utils/request";

export function* doGetStudentDayOfExamRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/place-date/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamDayFormData(response.data.data));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDownloadDocRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/download-hall-ticket/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );

    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamDownloadData(response.data.data));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetPassFailDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/result/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setPassFailData(response.data.data));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAdmissionDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `student/exam/get/enrollment/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setAdmissionDetails(response.data.data));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doSubmitAdmissionRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      patch,
      `student/exam/complete/admission/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ""
      }`,
      {}
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* ExamDayAndPassFailRepoSaga() {
  yield takeLatest(actions.doGetStudentDayOfExam, doGetStudentDayOfExamRequest);
  yield takeLatest(actions.doGetDownloadDoc, doGetDownloadDocRequest);
  yield takeLatest(actions.doGetPassFailDetails, doGetPassFailDetailsRequest);
  yield takeLatest(actions.doGetAdmissionDetails, doGetAdmissionDetailsRequest);
  yield takeLatest(actions.doSubmitAdmission, doSubmitAdmissionRequest);
}
