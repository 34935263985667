import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { IPaymentDetails, PaymentTypeEnum } from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "studentPayment",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    togglePaymentConfirm: (state, action: PayloadAction<boolean>) => {
      state.paymentConfirm = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createPayment.${action.payload.key}`, action.payload.value);
    },
    doGetPaymentDetails: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    doGetExamPrice: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {
      state.loading = true;
    },
    doUpdatePayment: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    doCreatePayment: (
      state,
      action: PayloadAction<{
        id: string;
        address: any;
        callback: (rData: any) => void;
      }>
    ) => {},
    setPaymentDetails: (state, action: PayloadAction<IPaymentDetails>) => {
      state.paymentDetails = action.payload;
    },
    setPaymentKeyData: (state, action: PayloadAction<string>) => {
      state.paymentKeyData = action.payload;
      state.stripeShow = true;
    },
    toggleStripeShow: (state, action: PayloadAction<boolean>) => {
      state.stripeShow = action.payload;
    },
    clearPaymentForm: (state) => {
      state.createPayment.email = "";
      state.createPayment.phoneNo = "";
      state.createPayment.paymentMethod = PaymentTypeEnum.NON;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
