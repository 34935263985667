import { UniversitySettingState } from ".";

export const initialState: UniversitySettingState = {
  loading: false,
  passwordForm: {
    oldPassword: "",
    newPassword: "",
    newConfirmPassword: "",
  },
  basicDetails: {
    _id: "",
    title: "",
    address: "",
    description: "",
  },
  emailForm: {
    currentEmail: "",
    newEmail: "",
    newConfirmEmail: "",
  },
  privacyPolicyForm: {
    privacyPolicy: "",
  },
};
