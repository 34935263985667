import "./App.css";
import MainRoutes from "./routes";
import Injector from "./Components/Injector";

function App() {
  return (
    <>
      <Injector>
        <MainRoutes />
      </Injector>
    </>
  );
}

export default App;
