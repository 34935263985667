import { UniversityExamDetailsState } from ".";
import { PaymentTypeEnum } from "../../../../Student/Payment/types";

export const initialState: UniversityExamDetailsState = {
  loading: false,
  buttonLoading: false,
  registrationDetails: {
    _id: "",
    examId: "",
    university: "",
    studentId: "",
    name1: "",
    name2: "",
    address: "",
    profilePic: "",
    nameOfHighSchool: "",
    department: "",
    dateOfGraduation: "",
    dateOfBirth: "",
    additionalFieldsData: [],
    createdAt: "",
  },
  submissionMailing: {
    _id: "",
    schoolAddressFieldsData: [],
    uploadDocumentFieldsData: [],
    createdAt: "",
  },
  paymentDetails: {
    _id: "",
    paymentId: "",
    amount: 0,
    paymentMethod: PaymentTypeEnum.NON
  }
};
