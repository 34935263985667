import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";
import { IApplicationInformationData, IExamDetailsInformation } from "./types";
import { log } from "../../../../utils/logger";
import {
  ENUM_ADDITIONAL_FIELD_TYPE,
  ENUM_FILE_TYPE,
  ISchoolAddress,
} from "../Submissions&Mailings/types";

export const useClinicSlice = createSlice({
  name: "applicationExamination",
  initialState,
  reducers: {
    setSelectedIndex: (state, action: PayloadAction<number>) => {
      state.isSelectedIndex = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsEditable: (state, action: PayloadAction<boolean>) => {
      state.isEditable = action.payload;
    },
    /* ****** */
    updateRegistrationAdditionalFieldsFormValue: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      set(
        state,
        `applicationInformation.registrationAdditionalFields[${action.payload.index}].${action.payload.key}`,
        action.payload.value
      );
      if (action.payload.key == "type") {
        state.applicationInformation.registrationAdditionalFields[
          action.payload.index
        ].options = [];
        switch (action.payload.value as ENUM_ADDITIONAL_FIELD_TYPE) {
          case ENUM_ADDITIONAL_FIELD_TYPE.MULTI_SELECT:
            state.applicationInformation.registrationAdditionalFields[
              action.payload.index
            ].options?.push("");
            break;
          case ENUM_ADDITIONAL_FIELD_TYPE.SINGLE_SELECT:
            state.applicationInformation.registrationAdditionalFields[
              action.payload.index
            ].options?.push("");

            break;
          case ENUM_ADDITIONAL_FIELD_TYPE.SELECT_YES_NO:
            state.applicationInformation.registrationAdditionalFields[
              action.payload.index
            ].options?.push("Yes");
            state.applicationInformation.registrationAdditionalFields[
              action.payload.index
            ].options?.push("No");
            break;
          case ENUM_ADDITIONAL_FIELD_TYPE.FILE_SELECT:
            state.applicationInformation.registrationAdditionalFields[
              action.payload.index
            ].fileSelectFileType = ENUM_FILE_TYPE.PDF;
            state.applicationInformation.registrationAdditionalFields[
              action.payload.index
            ].maxNoOfFiles = 1;
            break;
          default:
            break;
        }
      }
    },
    updateRegistrationApplicationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `applicationInformation.${action.payload.key}`,
        action.payload.value
      );
      log(state.applicationInformation, "state");
    },

    addApplicationInformationFormValue: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      state.applicationInformation.registrationAdditionalFields[
        action.payload.index
      ].options?.push("");
    },
    addNewApplicationInformationFormValue: (
      state,
      action: PayloadAction<ISchoolAddress>
    ) => {
      if (!state.applicationInformation.registrationAdditionalFields) {
        state.applicationInformation.registrationAdditionalFields = [];
      }
      state.applicationInformation.registrationAdditionalFields.push(
        action.payload
      );
    },
    removeSchoolAddressFormOptionValue: (
      state,
      action: PayloadAction<{ index: number; innerIndex: number }>
    ) => {
      state.applicationInformation.registrationAdditionalFields[
        action.payload.index
      ].options?.splice(action.payload.innerIndex, 1);
    },

    updateRegistrationFormOptionValue: (
      state,
      action: PayloadAction<{
        index: number;
        innerIndex: number;
        key: string;
        value: any;
      }>
    ) => {
      set(
        state,
        `applicationInformation.registrationAdditionalFields[${action.payload.index}].${action.payload.key}[${action.payload.innerIndex}]`,
        action.payload.value
      );
    },
    doRemoveRegistrationAdditionalFieldsData: (
      state,
      action: PayloadAction<{ index: number; id: string }>
    ) => {
      if (action.payload.id.length > 0) {
        state.applicationInformation.registrationAdditionalFields[
          action.payload.index
        ].isDeleted = true;
      } else {
        state.applicationInformation.registrationAdditionalFields.splice(
          action.payload.index,
          1
        );
      }
    },
    doUpdateApplicationInformation: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    doGetApplicationInformation: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.isEditable = true;
    },

    setApplicationInformation: (
      state,
      action: PayloadAction<IApplicationInformationData>
    ) => {
      if (action.payload) {
        state.applicationInformation = action.payload;
      }
    },
    clearRegistrationApplicationForm: (state) => {
      state.applicationInformation._id = "";
      state.applicationInformation.isName1 = true;
      state.applicationInformation.isName2 = true;
      state.applicationInformation.isAddress = true;
      state.applicationInformation.isNameOfHighSchool = true;
      state.applicationInformation.isDepartment = true;
      state.applicationInformation.isDateOfGraduation = true;
      state.applicationInformation.isDateOfBirth = true;
      state.applicationInformation.isProfilePic = true;
      state.applicationInformation.registrationAdditionalFields = [];
    },

    /* ****** */
    doGetExamDetailsInformation: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.isEditable = true;
    },
    setExamDetailsInformation: (
      state,
      action: PayloadAction<IExamDetailsInformation>
    ) => {
      state.examDetailsInformation = action.payload;
      state.examDetailsInformation.examResultPublishTime =
        action.payload.examResultDate;
    },
    //********** */
    updateExamDetailsInformationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `examDetailsInformation.${action.payload.key}`,
        action.payload.value
      );
    },
    doUpdateFeeEntry: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    doUpdateRemark: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    clearFeeEntryForm: (state) => {
      state.examDetailsInformation.feeAmount = 0;
      state.examDetailsInformation.feeLastDate = "";
      state.examDetailsInformation.feeRemark = "";
      state.examDetailsInformation.remark = "";
    },
    doUpdateExamDate: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    doUpdateExamHallTicketNumber: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},

    clearExaminationDateForm: (state) => {
      state.examDetailsInformation.examDate = "";
      state.examDetailsInformation.examPlace = "";
      state.examDetailsInformation.examDateRemark = "";
    },
    clearExamHallTicketNumberForm: (state) => {
      state.examDetailsInformation.hallTicketNumPrefix = "";
    },
    doUpdateExamPassFailDate: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    doUpdateExamPassFailContent: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    clearPassFailForm: (state) => {
      state.examDetailsInformation.examDate = "";
      state.examDetailsInformation.examPlace = "";
      state.examDetailsInformation.examDateRemark = "";
      state.examDetailsInformation.examSpecialStudentContent = "";
      state.examDetailsInformation.examSpecialStudentPassContent = "";
      state.examDetailsInformation.examSpecialStudentFailContent = "";
      state.examDetailsInformation.isExamSpecialStudentContent = false;
      state.examDetailsInformation.examFailContent = "";
      state.examDetailsInformation.examPassContent = "";
      state.examDetailsInformation.examResultPublishTime = "";
      state.examDetailsInformation.examTestPoint = false;
      state.examDetailsInformation.offset = "";
      state.examDetailsInformation.minTestResult = 0;
    },
    doUpdateResultDate: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    clearResultDateForm: (state) => {
      state.examDetailsInformation.examResultPublishDate = "";
      state.examDetailsInformation.examResultPublishTime = "";
      state.examDetailsInformation.examTestPoint = false;
      state.examDetailsInformation.offset = "";
      state.examDetailsInformation.minTestResult = 0;
    },
    doUpdateEnrolment: (
      state,
      action: PayloadAction<{ examId: string; callback: () => void }>
    ) => {},
    clearUpdateEnrolmentForm: (state) => {
      state.examDetailsInformation.enrolmentContent = "";
      state.examDetailsInformation.enrolmentRemark = "";
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
