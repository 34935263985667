import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  if (state && state.studentExamList) {
    return state.studentExamList;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectStudentExamList = createSelector(
  [selectDomain],
  (state) => state.examList
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectSortColumn = createSelector(
  [selectDomain],
  (state) => state.orderBy
);
export const selectSortDir = createSelector(
  [selectDomain],
  (state) => state.orderDir
);

export const selectStudentAppliedExamList = createSelector(
  [selectDomain],
  (state) => state.appliedExamList
);
export const selectAppliedPageNo = createSelector(
  [selectDomain],
  (state) => state.appliedExamPageNo
);
export const selectAppliedPageSize = createSelector(
  [selectDomain],
  (state) => state.appliedExamPageSize
);
export const selectAppliedTotalRow = createSelector(
  [selectDomain],
  (state) => state.appliedExamTotalRow
);
export const selectAppliedSortColumn = createSelector(
  [selectDomain],
  (state) => state.appliedExamOrderBy
);
export const selectAppliedSortDir = createSelector(
  [selectDomain],
  (state) => state.appliedExamOrderDir
);
