import { UniversityExamListState } from ".";

export const initialState: UniversityExamListState = {
  loading: false,
  pageNo: 1,
  pageSize: 8,
  totalRow: 0,
  examList: [],
  orderBy: "",
  orderDir: "",
  buttonLoading: false,
  studentList: [],
  studentPageNo: 1,
  studentPageSize: 8,
  studentTotalPages: 0,
  studentOrderBy: "",
  studentOrderDir: "",
  confirmModal: false,
  selectedId: null,
  selectedIndex: null,
  selectedType: null,
  search: "",
  examPreview: false,
};
