export interface IPaymentDetails {
  _id: string;
  feeLastDate: string;
  title: string;
  remark: string;
  amount: number;
}
export interface IPayment {
  email: string;
  phoneNo: string;
  paymentMethod: PaymentTypeEnum;
}
export enum PaymentTypeEnum {
  NON = 0,
  CARD = 1,
  DEPT = 2,
}

export interface StudentPaymentState {
  paymentDetails: IPaymentDetails;
  createPayment: IPayment;
  loading: boolean;
  buttonLoading: boolean;
  paymentKeyData: string;
  stripeShow: boolean;
  paymentConfirm: boolean;
}

export type InitialState = StudentPaymentState;
