import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
const Dashboard = lazy(() => import("../Pages/Dashboard"));
const Login = lazy(() => import("../Components/Login"));
const Settings = lazy(() => import("../Pages/Settings"));
const ExamList = lazy(() => import("../Pages/ExamList"));
const ExamStudentList = lazy(() => import("../Components/Exams"));
const ExamStudentDetails = lazy(() => import("../Components/Exams/form"));
const AdmissionTicket = lazy(() => import("../Pages/AdmissionTicket"));
const MyPage = lazy(() => import("../Pages/MyPage"));
const Test = lazy(() => import("../Pages/Test"));
const Layout = lazy(() => import("../Components/Layout"));
const StudentLayout = lazy(() => import("../Components/Layout/StudentLayout"));
const Page404 = lazy(() => import("../Pages/404"));

function MainRoutes() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            <Route path="/u/:title/" element={<Layout isSidebar={false} />}>
              <Route path="" element={<Login Page={"Sign-in"} />} />
            </Route>
            <Route path="/u/:title/" element={<Layout isSidebar />}>
              <Route path="dashboard/:category" element={<Dashboard />} />
              <Route
                path="dashboard/:category/:formName"
                element={<Dashboard />}
              />
              <Route path="setting" element={<Settings />} />
              <Route path="setting/:page" element={<Settings />} />
              <Route path="exams" element={<ExamList />} />
              <Route path="exams/:id" element={<ExamStudentList />} />
              <Route
                path="exams-details/:id"
                element={<ExamStudentDetails />}
              />
              <Route path="admission-ticket" element={<AdmissionTicket />} />
            </Route>
            <Route
              path="/s/:title/"
              element={<StudentLayout isLogin={false} />}
            >
              <Route path="" element={<Login Page={"university"} />} />
              <Route path="register" element={<Login Page={"university"} />} />
              <Route
                path="sign-in"
                element={<Login Page={"Student-Sign-in"} />}
              />
              <Route
                path="forgot-password"
                element={<Login Page={"forgot-password"} />}
              />
            </Route>
            <Route path="/s/:title/" element={<StudentLayout isLogin />}>
              <Route path="my-page" element={<MyPage />} />
              <Route path="my-page/:page" element={<MyPage />} />
              <Route path="test" element={<Test />} />
              <Route path="test/:testId" element={<Test form={true} />} />
              <Route path="test/:testId/:page" element={<Test form={true} />} />
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default MainRoutes;
